import React from "react";
import TablePaginationActions from "./Pagination";
import TableRow from "@material-ui/core/TableRow";
import { TableFooter, TablePagination } from "@material-ui/core";

const pageOptions = [5, 10, 25, { label: "All", value: -1 }];
const selectProps = {
    inputProps: {
        "aria-label": "rows per page",
    },
    native: true,
}
export const TableFoot = ({ count, page, rows, handleChangePage, handleChangeRowsPerPage }) => {
    return (
        <TableFooter>
            <TableRow>
                <TablePagination
                    rowsPerPageOptions={pageOptions}
                    colSpan={3}
                    count={count}
                    rowsPerPage={rows}
                    page={page}
                    SelectProps={selectProps}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                    ActionsComponent={TablePaginationActions}
                />
            </TableRow>
        </TableFooter>
    );
};
