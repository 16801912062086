import {NOTIFIES} from "../actions/types"
const initialState = {
    notify:{
        clinicalData: true
    }
    
}
export default function reducer(state = initialState ,{type, payload} ){        
    switch(type){   
        case NOTIFIES: {
            return payload
        }
        default: {                        
            return state
        }
    }
}