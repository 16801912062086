export default function authHeader() {
    const user = JSON.parse(localStorage.getItem('user'));
    if (user && user.access_token) {
        //Depende del backend :D
      return { Authorization: `Token ${user.access_token}`};
    } else {
      return {};
    }
}

