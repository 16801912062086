import axios from "axios";
import API_URL from "./api-clinica-url";
import authHeader from "./auth-header";

const getCies = () =>
    new Promise((resolve, reject) => {
        axios
            .get(`${API_URL}/api/diagnostico/`, { headers: authHeader() })
            .then((response) => resolve(response.data))
            .catch((response) => reject(response)) 
    });

export default getCies;
