import SettingsIcon from "@material-ui/icons/Settings";
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import { cePath } from "../../helpers/utils";
let i = 0;
const menuLaboratorio = [
    {
        id: i++,
        nombre: "Examen",
        icon: <AssignmentIndIcon />,
        route: `${cePath}/examen`,
    },

    {
        id: i++,
        nombre: "Configuración",
        icon: <SettingsIcon />,
        route: `${cePath}/configuracion`,
    },
    {
        id: i++,
        nombre: "Cerrar Sesión",
        icon: <ExitToAppIcon />,
        route: "/login",
    },
];
export default menuLaboratorio;
