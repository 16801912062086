import React from "react";
import { Route, Switch } from "react-router";
import { EnfermeriaView } from "../components/views/Enfermeria/EnfermeriaView";
import { HojaEvoView } from "../components/views/Hoja_Evo/HojaEvoView";
import { KardexView } from "../components/views/Kardex/KardexView";
import { RegistroAdmisionView } from "../components/views/RegistroAdmision/RegistroAdmisionView";
import { enfermeriaPath } from "../helpers/utils";

export default function EnfermeriaRouter() {
    return (
        <Switch>
            <Route exact path={enfermeriaPath} component={EnfermeriaView} />
            <Route path={`${enfermeriaPath}/:room/registro`} 
                component={RegistroAdmisionView} />
            <Route path={`${enfermeriaPath}/:room/hoja_evo`} 
                component={HojaEvoView} />
            <Route path={`${enfermeriaPath}/:room/kardex`} 
                component={KardexView} />
        </Switch>
    );
}
