import Button from "@material-ui/core/Button";
import DateFnsUtils from "@date-io/date-fns";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from "@material-ui/pickers";
import { React } from "react";
import ClinicalTextField from "../../core/Field/TextField";
import { connect, useSelector } from "react-redux";
import { setPatientPersonalData } from "../../../redux/actions/paciente";
import { RadioField } from "../../core/Field/radio/RadioField";
import { RadioItem } from "../../core/Field/radio/RadioItem";

const useStyles = makeStyles(() => ({
    item: {
        textAlign: "center",
    },

}));

function DatosPersonalesForm(props) {
    const {handleSubmit, form, setForm, formErrors, setFormErrors} = props;
    
    const { loading } = useSelector(state => state.UI)
    const classes = useStyles();
    
    const handleChange = ({ target }) => {
        const { name, value } = target;
        setForm({
            ...form,
            [name]: value,
        });
    };
    const handleTelephoneChange = ({ target }) => {
        const { name, value } = target;
        setForm({
            ...form,
            telefonos: form.telefonos.map((element, index) => {
                if (index === parseInt(name)) {
                    return {telefono: value};
                }
                return element;
            })},
        );
    };

    const handleDateChange = (date) => {
        setForm({
            ...form,
            fechaNacimiento: date,
        });
    };

    const changeError = e =>{
        if(formErrors?.cedula){
            setFormErrors({
                ...formErrors,
                cedula:""
            })
        }
    }
    const changeErrorTelephone = e =>{
        const { name } = e.target;
        setFormErrors((formy) => ({
            ...formErrors,
            telefono: formy.telefono.map((element, index) => {
                if (index === parseInt(name)) return "";
                return element;
            }),
        }));
    }
    return (
        <form onSubmit={handleSubmit}>
            <Grid container justify="center" spacing={3}>
                <ClinicalTextField
                    error={formErrors?.cedula ? true:false}
                    lg={6}
                    sm={12}
                    name="cedula"
                    form={form}
                    label="Cedula"
                    helperText={formErrors?.cedula ?? ""}
                    handleChange={handleChange}
                    onInput={changeError}
                />

                <ClinicalTextField
                    lg={6}
                    sm={12}
                    name="nombres"
                    form={form}
                    label="Nombres"
                    handleChange={handleChange}
                />

                <ClinicalTextField
                    lg={6}
                    sm={12}
                    name="apellidoPaterno"
                    form={form}
                    label="Apellido Paterno"
                    handleChange={handleChange}
                />

                <ClinicalTextField
                    lg={6}
                    sm={12}
                    name="apellidoMaterno"
                    form={form}
                    label="Apellido Materno"
                    handleChange={handleChange}
                />

                <ClinicalTextField
                    lg={6}
                    sm={12}
                    name="email"
                    form={form}
                    label="Email"
                    handleChange={handleChange}
/>

                <ClinicalTextField
                    error={formErrors?.telefono[0] ? true:false}
                    lg={6}
                    sm={12}
                    name="0"
                    form={form.telefonos}
                    label="Teléfono"
                    handleChange={handleTelephoneChange}
                    onInput={changeErrorTelephone}
                    helperText={formErrors?.telefono[0] ?? ""}
                />

                <ClinicalTextField
                    error={formErrors?.telefono[1] ? true:false}
                    className={classes.root}
                    lg={6}
                    sm={12}
                    name="1"
                    form={form.telefonos}
                    required={false}
                    label="Teléfono 2 (Opcional)"
                    handleChange={handleTelephoneChange}
                    onInput={changeErrorTelephone}
                    helperText={formErrors?.telefono[1] ?? ""}
                />

                <Grid item lg={6} sm={12} xs={12}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                            required
                            fullWidth
                            variant="inline"
                            format="MM/dd/yyyy"
                            label="Fecha de nacimiento"
                            placeholder="mm/dd/yyyy"
                            value={form.fechaNacimiento}
                            onChange={handleDateChange}
                            KeyboardButtonProps={{
                                "aria-label": "change date",
                            }}
                        />
                    </MuiPickersUtilsProvider>
                </Grid>
                {/* <SelectField
                    lg={6}
                    label="Sexo"
                    handleChange={handleChange}
                    form={form}
                    name="sexo"
                >
                    <MenuItem value={"M"}>Masculino</MenuItem>
                    <MenuItem value={"F"}>Femenino</MenuItem>
                </SelectField> */}

                <ClinicalTextField
                    lg={6}
                    sm={12}
                    name="domicilio"
                    form={form}
                    label="Domicilio"
                    handleChange={handleChange}
                />

                <Grid item sm={12} lg={6}>
                        <RadioField title="Sexo"
                            handleChange={handleChange}
                            name="sexo"
                            value={form.sexo}
                            row
                            required
                        >
                            <RadioItem value="M" label="Masculino"/>
                            <RadioItem value="F" label="Femenino" />
                        </RadioField>
                </Grid>

                <Grid item lg={12} sm={12} xs={12} className={classes.item}>
                    <Button
                        variant="contained"
                        color="primary"
                        type="submit"
                        disabled={loading}
                    >
                        Registrar
                    </Button>
                </Grid>
            </Grid>


        </form>
    );
}
const mapStateToProps = (state) => ({ form: state.paciente.personalData });
const mapDispatchToProps = (dispatch) => ({
    setForm:(form) =>dispatch(setPatientPersonalData(form))
});

export default connect(mapStateToProps, mapDispatchToProps)(DatosPersonalesForm);