import React from 'react'
import AppBar from "@material-ui/core/AppBar";
import { useSelector } from 'react-redux';

export const GeneralApp = (props) => {
    
    const { nightMode } = useSelector(state => state.UI)
    const colorBar = nightMode ? "default" : "primary";
    return (
        <AppBar color={colorBar} {...props}>
            {props.children}
        </AppBar>
    )
};