import React from "react";
import { Route, Switch } from "react-router";
import { cePath } from "../helpers/utils";
import ConfiguracionView from "../components/views/Configuration/ConfiguracionView";
import Default from "../components/views/Default";
export default function GeneralDashboardRouter({children}) {
    return (
        <Switch>
            {children}
            <Route
                    path={`${cePath}/configuracion`}
                    component={ConfiguracionView}
                />

            <Route exact path={`${cePath}`} component={Default} />
        </Switch>
    );
}
