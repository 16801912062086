import React from 'react';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';

export default function Configuracion(props) {
  
  const {handleChange,checked} = props

  return (
    <FormGroup row>      
      <FormControlLabel
        control={
          <Switch
            checked={checked}            
            onChange={handleChange}
            name="checkedB"
            color="primary"
          />
        }
        label="Modo nocturno"
      />      
    </FormGroup>
  );
}
