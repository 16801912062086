import { paciente } from "../actions/types"
const query = {
    alergia:"",
    nombre:"",
    cedula:"",
}
const personalData = {
    nombres: "",
    apellidoMaterno: "",
    apellidoPaterno: "",
    email: "",
    cedula: "",
    domicilio: "",
    sexo: "M",
    fechaNacimiento: new Date().toString(),
    telefonos: [{telefono:""}, {telefono:""}],
}
const examsCredentials =JSON.parse(localStorage.getItem("patient-user")) || {
    cedula: null,
    examen_laboratorio: []
}
const initialState = {
    query,
    personalData,
    examsCredentials,
}
export default function reducer(state = initialState ,{type, payload} ){
    switch(type){
        case paciente.setPaciente:{
            return {
                ...state,
                ...payload
            }
        }
        case paciente.clearPaciente:{
            return initialState;
        }
        case paciente.clearPersonalData:{
            return{
                ...state,
                personalData,    
            }
        }
        case paciente.setDataPersonalPaciente:{
            return {
                ...state,
                ...payload,
            }
        }
        case paciente.loginPatientSuccess:{
            return {
                ...state,
                ...payload,
            }

        }
        default:{
            return state
        }
    }
}