import React from 'react'
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
export const TableHeader = (props) => {
    const { headers } = props;
    return (
        <TableHead>
			<TableRow>
				{headers.map((header,index) =>
					<TableCell key={`${header.toString()} ${index}`}
					 align="center" {...props} style={{ width: header.size ?? "" }}
					 >
						{header.label}
					</TableCell>
				)}                                                
			</TableRow>
		</TableHead>
    )
}
