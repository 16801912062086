import axios from "axios";
import { setDialog, setUiMessage } from "../redux/actions/ui";
import API_URL from "./api-clinica-url";
import authHeader from "./auth-header";

export const getExamFile = (fileName) =>
    new Promise((resolves, rejects) => {
        axios
            .get(`${API_URL}/api/fileDowload/${fileName}/`)
            .then((response) => resolves(response))
            .catch((response) => rejects(Error(response)));
    });

export const getMedicos = () => dispatch =>
    new Promise((resolves, rejects) => {
        axios
            .get(`${API_URL}/api/medicos/`, { headers: authHeader() })
            .then((response) => resolves(response.data))
            .catch((err) => {
                dispatch(setUiMessage(err.toString()));
                dispatch(setDialog(true));
                rejects()
            }) 
    });

export default getMedicos;
