import SettingsIcon from "@material-ui/icons/Settings";
import NoteIcon from "@material-ui/icons/Note";
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import { cePath } from "../../helpers/utils";

let i = 0;
const menuMedico = [
    {
        id: i++,
        nombre: "Ficha Médica",
        icon: <NoteIcon />,
        route: `${cePath}/ficha_medica`,
        
    },    
    {
        id: i++,
        nombre: "Historial Clínico",
        icon: <AssignmentIndIcon />,
        route: `${cePath}/historial_clinico`,
    },

    {
        id: i++,
        nombre: "Configuración",
        icon: <SettingsIcon />,
        route: `${cePath}/configuracion`,
    },
    {
        id: i++,
        nombre: "Cerrar Sesión",
        icon: <ExitToAppIcon />,
        route: "/login",
    },
];
export default menuMedico;
