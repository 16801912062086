import React from "react";
import FichaMedica from "./FichaMedica";
import TitleView from "../../core/TitleView";
export default function FichaMedicaView() {
    return (
        <>
            <TitleView title={`Ficha médica`} />
            <FichaMedica />
        </>
    );
}
