import { LOGIN_SUCCESS, LOGIN_FAIL, LOGOUT } from "./types";
import login from "../../services/auth.service";
import { logout } from "../../services/auth.service";
import { setDialog, setUiMessage, uiLoading } from "./ui";
const handleError = (error, field) =>
    (error?.response?.data && field) ||
    error?.message ||
    error?.toString() || error;
export const factoryError = (error) => {
    // for(let obj in error)
    //     console.log(obj)
    const data = error?.response?.data;
    
    if (data?.non_field_errors)
        return handleError(error, data?.non_field_errors[0]);
    else if (data?.username) return handleError(error, data?.username[0]);
    else if (data?.password) return handleError(error, data?.password[0]);
    else{
        return `${error?.toJSON()?.message}: contacte al soporte maxpowerxd8y9@gmail.com`;
    } 
};

const loginAuth = (onlyCrypted) => (dispatch) => {
    dispatch(uiLoading(true));
    return new Promise((resolve,reject)=>{
        login(onlyCrypted).then((data) => {
            //MOdifico el estado a LOGIN_SUCCESS y su payload a la response
            dispatch({
                type: LOGIN_SUCCESS,
                //Tiene el puto token que es el response D:
                payload: { user: data },
            });
            resolve();
        })
        .catch((error) => {
            // Guardo el msj de error
            const message = factoryError(error);
            dispatch(loginFail());
            // Seteo el msj para anexarle el error
            dispatch(setUiMessage(message));
            dispatch(setDialog(true));
            
            // reject(error?.response?.data);
            reject(message);
        })
        .finally(()=>dispatch(uiLoading(false)));
    })
};
export const loginFail = () => dispatch =>{
    dispatch({
        type: LOGIN_FAIL,
    });
}
//Cambio el estado a LOGOUT
export const logoutAuth = () => (dispatch) => {
    logout();
    dispatch({
        type: LOGOUT,
    });
};

export default loginAuth;
