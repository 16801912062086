import { ui } from "../actions/types";
const getDark = localStorage.getItem("isDark")
const initialState = {
    nightMode: getDark ? JSON.parse(getDark): true,
    loading: false,
    message: "",
    dialogMajor: false,
    dialog: false,
    dialogView:false,
    cargarView: false,
}
export const UI = (state = initialState, action) => {
    switch (action.type) {
        case ui.setMessage:
            return { 
                ...state,
                message: action.payload 
            };

        case ui.clearMessage:
            return { 
                ...state,
                message: "" 
            };
        case ui.loading:
            return{
                ...state,
                loading: action.payload
            }

        case ui.nightMode:
            localStorage.setItem("isDark",JSON.stringify(action.payload) );
            return {
                ...state,
                nightMode: action.payload
            }
        case ui.dialogMajor:{
            return{
                ...state,
                dialogMajor: action.payload
            }
        }
        case ui.dialog:{
            return{
                ...state,
                dialog: action.payload
            }
        }
        case ui.dialogView:{
            return{
                ...state,
                dialogView: action.payload
            }
        }

        case ui.cargarView:{
            return{
                ...state,
                cargarView: action.payload
            }
        }

        default:
            return state;
    }
}