import { medicalRecord } from "../actions/types";

const initialState = {
    id: 0,
    medico:null,
    motivo:null,
    diagnostico:null,
    fechaIngreso:null,
    tratamiento:[],
};

export const fichaMedica = (state = initialState, action) => {
    switch(action.type) {
        case medicalRecord.medicalRecordActive:
            return action.payload;
        default:
            return state;
    }
}
