import Grid from "@material-ui/core/Grid";
import React, { useState, useEffect, useRef } from "react";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { connect } from "react-redux";
import {getPaciente} from "../../../services/paciente-service";
import InfoCard from '../../core/Card/InfoCard';
import InfoHeaderCard from '../../core/Card/InfoHeaderCard';
import Alert from '../../core/Alert';
import { setPatientPersonalData } from "../../../redux/actions/paciente";
const DatosPaciente = (props) => {  
    const [data,setData] = useState(null)
    const [loading, setLoading] = useState(false)
    const {cedula} = props.getPaciente;
    const ref = useRef(props.setFormPersonalData)
    useEffect(() => {
        if (cedula) {            
            getPaciente(cedula)            
                .then((response) => {
                    ref.current(response)
                    setData(response)
                })
                .catch((error) => console.log(error))
                .finally(()=> setLoading(false))
        }
    }, [cedula]);
    const id = () => props.getPaciente.nombre ?? ""
    const getSexo = () =>{
        return data.sexo === "M" ? "Masculino":"Femenino"    
    } 
    return (
        <Grid container justify="flex-start">            
                {!loading && data ?
                <Grid item lg={12} sm={12} xs={12}>
                    <Card>
                        <InfoHeaderCard title={id()} date={data.fechaNacimiento}/>
                        <CardContent>
                            <Grid container justify="space-between" alignItems="center" spacing={3}>
                                <InfoCard title="Cedula:" value={data.cedula}/>
                                {data.telefonos.map((guia,index) =>
                                        <InfoCard key={`${guia + index}`} title={`Telefono ${index + 1}:`} value={guia.telefono}/>
                                )}
                                <InfoCard title="Sexo:" value={getSexo()}/>
                                <InfoCard lg={6} sm={6}
                                    title="Residencia:" value={data.domicilio}/>
                                <InfoCard lg={6} sm={6}
                                    title="Email:" value={data.email}/>
                                <Grid lg={6} sm={12} xs={12}>
                                    {props.children}                                    
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
                :
                <Grid item lg={12} sm={12} xs={12}>
                    <Alert type="warning" title="Advertencia"
                    description="No se encuentra el paciente :("/>
                </Grid>
            }
            
        </Grid>
    );
};
//state...paciente :D
const mapStateToProps = ({paciente}) => ({ getPaciente: paciente.query });
const mapDispatchToProps = (dispatch) =>({
    setFormPersonalData: (form) => dispatch(setPatientPersonalData(form)),
})
export default connect(mapStateToProps, mapDispatchToProps)(DatosPaciente);