import { getMode } from "./getMode"
//DEV or within params
const getUrl = (mode = "PRODUCTION") =>{
    const selectedMode = getMode(mode)
    //"LOCAL"
    if(selectedMode === 1){
        return "http://127.0.0.1:8000"
    }
    //"DEV"
    else if(selectedMode === 2){
        return "https://api-clinica-dev.herokuapp.com"
    }
    //"PRODUCTION"
    else if(selectedMode === 3){
        return "https://apiclinicagilces.herokuapp.com"
    }
    return selectedMode
}
const API_URL = getUrl("PRODUCTION")
export default API_URL