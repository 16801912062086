import {ARCHIVO} from "../actions/types"
const initialState = {
    archivos: null
}
export default function reducer(state = initialState ,{type, payload} ){        
    switch(type){   
        case ARCHIVO: {
            return { 
                archivos: payload            
            };        
        }
        default: {                        
            return state
        }
    }
}