import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import { useDispatch } from "react-redux";

import { makeStyles } from "@material-ui/core/styles";
import React, { useRef, useState } from "react";
import Table from "../Table/Table";
import { getFichaMedicaPacienteById } from "../../../services/paciente-service";
import {
    getFichasMedicas,
    postFichasMedicas,
} from "../../../services/ficha-medica-service";

import TextField from "../../core/Field/TextField";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import PictureAsPdfOutlinedIcon from "@material-ui/icons/PictureAsPdfOutlined";
import SaveIcon from "@material-ui/icons/Save";
import { connect } from "react-redux";
import FileDownload from "js-file-download";
import UltimaFichaMedica from "../UltimaFichaMedica";
import { setDialogTurn, setDialogView } from "../../../redux/actions/ui";
import { medicalRecordActive } from "../../../redux/actions/fichaMedica";
import { CheckboxField } from "../Field/checkbox/CheckboxField";

import CieAutocomplete from "../Field/autocomplete/CieAutocomplete";

import {getCias} from "../../../redux/actions/paciente";


const useStyles = makeStyles((theme) => ({

    root: {
        textAlign: "center",
        paddingTop: theme.spacing(3),
    },
    item: {
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(3),
    },
    outline: {
        textAlign: "center",
        paddingTop: theme.spacing(3),
        [theme.breakpoints.up("md")]: {
            paddingLeft: theme.spacing(3),
        },
    },
}));


const getTreatment = (n) => {
    let initial = {
        row: 0,
        medicamento: "",
        cantidad: 1,
        preescripcion: "",
        required: true,
    };
    const a = [initial];
    for (let i = 1; i <= n; i++)
        a.push({ ...initial, row: i, required: false });
    return a;
};
function DiagnosticoForm(props) {
    const classes = useStyles();
    const [count, setCount] = useState(2);
    const [disable, setDisable] = useState(true);
    const { dialogView } = props.UI;
    const [title, setTitle] = useState("")
    const dispatch = useDispatch();
    const ref = useRef(dispatch);


    const [cies, setCie] = React.useState([]);


    const [form, setForm] = useState({
        motivo: "",
        diagnostico: "",
        laboratorio: false,
        treatment: getTreatment(count),
    });


    React.useEffect(() => {
        ref.current(getCias())
            .then(response => {
                setCie(response);
            })
            .catch(()=>{
                setTitle("Advertencia :0");
            })
    }, []);

    const handleAdd = () => {
        setCount(count + 1);

        setForm((formy) => ({
            ...formy,
            treatment: [
                ...formy.treatment,
                {
                    row: count + 1,
                    medicamento: "",
                    cantidad: 0,
                    preescripcion: "",
                    required: false,
                },
            ],
        }));
    };

    const handleChangeTreatment = ({ target }, id) => {
        const { name, value } = target;
        setForm((formy) => ({
            ...form,
            treatment: formy.treatment.map((e, index) => {
                if (index === parseInt(id)) e[`${name}`] = value;
                return e;
            }),
        }));
    };
    const handleChange = ({ target }) => {
        const { name, value } = target;
        const check = target.hasOwnProperty('checked') ? target.checked : value 
        setForm({
            ...form,
            [name]: check,
        });
    };
    //Este maybe
    const getResult = () => {
        const results = [];
        form.treatment.forEach((e) => {
            const { row, required, ...treatment } = e;
            results.push(treatment);
        });
        return results;
    };

    const getNewForm = () => {
        const result = getResult();
        const { treatment, ...outTreatment } = form;
        return {
            ...outTreatment,
            treatment: result,
            idDatoClinico: props.paciente.id,
        };
    };
    const getPDF = () => {
        getFichasMedicas(props.paciente.id)
            .then((response) => {
                
                FileDownload(response, `Ficha_medica_${props.paciente.id}.pdf`);
            })
            .catch((error) => {
                console.log(error);
                alert(`PDF Error: ${error.statusText}`);
            });
    };

    const handleClick = (e) => {
        getPDF();
    };
    const handleClickLast = () => {
        getFichaMedicaPacienteById(props.paciente.ultimoId)
            .then((response) => {
                props.setMedicalRecordActive({
                    id: response[0].id,
                    medico: response[0].medico,
                    motivo: response[0].motivo,
                    evolution: response[0].evolution,
                    diagnostico: response[0].diagnostico,
                    fechaIngreso: response[0].fecha,
                    tratamiento: response[0].Fila,
                });

                props.setDialogView(true);
            })
            .catch((error) => {
                console.log(error);
            });
    };
    const handleSubmit = (e) => {
        e.preventDefault();
        setForm((formy) => ({
            ...form,
            treatment: formy.treatment.map((e) => {
                e.medicamento = e.medicamento.trim();
                return e;
            }),
        }));
        console.log(getNewForm())
        postFichasMedicas(getNewForm())
            .then((response) => {
                getPDF();
                setDisable(false);
            })
            .catch((error) => {
                alert(error);
            });
    };
    const handleKey = (e) => {
        if (e.key === "Enter") e.preventDefault();
    };
    return (
        <form onSubmit={handleSubmit} onKeyPress={handleKey}>
            <Grid container className={classes.item}>
                <Grid item lg={12} sm={12} xs={12}>
                    <CheckboxField
                        label="Examen de laboratorio"
                        onChange={handleChange}
                        checked={form.laboratorio}
                        name="laboratorio"
                        color="primary"
                    />
                </Grid>

                <TextField
                    lg={6}
                    name="motivo"
                    form={form}
                    label="Motivo de la consulta"
                    handleChange={handleChange}
                />

                <TextField
                    lg={12}
                    name="evolution"
                    form={form}
                    label="Evolución"
                    handleChange={handleChange}
                />                                          



                
                <CieAutocomplete
                    form={form}
                    name="diagnostico"
                    cies={cies}
                    handleChange={handleChange}
                    label = "Diagnostico"
                />
                
                <Grid item lg={12} sm={12} xs={12}>
                    <Table
                        styles={classes}
                        form={form}
                        handleAdd={handleAdd}
                        handleChange={handleChangeTreatment}
                        count={count}
                    />
                </Grid>
                <Grid item lg={12} sm={12} xs={12} className={classes.item}>
                    <Grid container justify="center">
                        <Grid
                            item
                            lg={2}
                            sm={6}
                            xs={12}
                            className={classes.root}
                        >
                            <Button
                                fullWidth
                                variant="contained"
                                color="primary"
                                type="submit"
                                disabled={!disable}
                                endIcon={<SaveIcon />}
                            >
                                Guardar
                            </Button>
                        </Grid>
                        {props.paciente.ultimoId > 0 && (
                            <Grid
                                item
                                lg={2}
                                sm={6}
                                xs={12}
                                className={classes.outline}
                            >
                                <Button
                                    fullWidth
                                    variant="outlined"
                                    color="primary"
                                    endIcon={<AttachFileIcon />}
                                    onClick={handleClickLast}
                                >
                                    Ultima vez
                                </Button>
                            </Grid>
                        )}
                        <Grid
                            item
                            lg={2}
                            sm={6}
                            xs={12}
                            className={classes.outline}
                        >
                            <Button
                                fullWidth
                                variant="outlined"
                                disabled={disable}
                                color="primary"
                                onClick={handleClick}
                                endIcon={<PictureAsPdfOutlinedIcon />}
                            >
                                Descargar
                            </Button>
                        </Grid>

                        {dialogView && <UltimaFichaMedica />}
                    </Grid>
                </Grid>
            </Grid>
        </form>
    );
}
const mapStateToProps = (state) => ({
    paciente: state.paciente.query,
    UI: state.UI,
});
const mapDispatchToProps = (dispatch) => ({
    close: (check) => dispatch(setDialogTurn(check)),
    setDialogView: (isOpen) => dispatch(setDialogView(isOpen)),
    setMedicalRecordActive: (medicalRecord) =>
        dispatch(medicalRecordActive(medicalRecord)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DiagnosticoForm);
