import CardHeader from "@material-ui/core/CardHeader";
import Avatar from "@material-ui/core/Avatar";
import { makeStyles } from "@material-ui/core/styles";
import { red } from "@material-ui/core/colors";
import { Typography } from "@material-ui/core";

const useStyles = makeStyles(() => ({
    avatar: {
        backgroundColor: red[500],
    },
}));
export default function InfoHeaderCard(props) {
    const classes = useStyles();
    return (
        <CardHeader 
            avatar={
                <Avatar aria-label="patient-card" className={classes.avatar}>
                    {props.title[0]}
                </Avatar>
            }
            title={<Typography variant="body1">
                {props.title}
            </Typography>}
            subheader={props.date}
        />
    );
}
