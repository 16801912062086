import React from 'react'
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import {sizesCard} from '../../../helpers/sizes'
const RenderBr = (props)=>{    
    return(
        <React.Fragment>
            {props.values.map(t=>
                <Typography variant="body2" color="textSecondary" component="p">
                    {t} <br/>
                </Typography>
                )}
        </React.Fragment>
    )
}
export default function InfoCard(props) {    
    const sizes = sizesCard(props)
    const isArray = () => typeof props.value === "object"
    
    return (
        <Grid item lg={sizes.lg} sm={sizes.sm} xs={sizes.xs}>
            <Typography variant="subtitle" color="textPrimary" component="b">
                {props.title}
            </Typography>
            <Typography variant="body2" color="textSecondary" component="p">                
                {isArray() ? <RenderBr values={props.value}/> : props.value}
            </Typography>
        </Grid>
    );
}
