import React from "react";
import { Route, Switch } from "react-router";
import { MainMenuView } from "../components/views/MainMenu/MainMenuView";
import { cePath, enfermeriaPath } from "../helpers/utils";
import DashboardRouter from "./DashboardRouter";
import EnfermeriaRouter from "./EnfermeriaRouter";

export default function MainMenuRouter() {
    return (
        <Switch>
            <Route exact path="/app" component={MainMenuView} />
            <Route path={cePath} component={DashboardRouter} />
            <Route path={enfermeriaPath} component={EnfermeriaRouter} />
        </Switch>
    );
}
