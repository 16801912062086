import { Fab, Button } from "@material-ui/core";
import { Add } from "@material-ui/icons";
import AssignmentIcon from "@material-ui/icons/Assignment";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from '@material-ui/icons/Edit';
import FindInPageIcon from '@material-ui/icons/FindInPage';

export const DeleteButton = (props) => (
    <Button {...props} variant="outlined" color="danger">
        <DeleteIcon />
    </Button>
);
export default function FloatingAddButton(props) {
    return (
        <Fab color="secondary" aria-label="add"{...props}>
          <Add />
        </Fab>
    );
  }

export const DiagnosticButton = (props) => (
    <Button variant="contained" color="primary" {...props}>
        <AssignmentIcon />
    </Button>
);
export const LabButton = (props) =>(
    <Button variant="outlined" color="primary" {...props}>
        <FindInPageIcon/>
    </Button>
)

export const EditButton = (props) => (
    <Button variant="outlined" color="primary" {...props}>
        <EditIcon />
    </Button>
);


