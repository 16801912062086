import React from "react";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { GeneralApp } from "./GeneralAppBar";

export const AppNavBar = (props) => {
    const { classes, handleDrawerToggle, name } = props;    
    return (
        <GeneralApp position="fixed" className={classes.appBar}>
            <Toolbar>
                <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    edge="start"
                    onClick={handleDrawerToggle}
                    className={classes.menuButton}
                >
                    <MenuIcon />
                </IconButton>
                <Typography variant="h5" noWrap>
                    {name}
                </Typography>
            </Toolbar>
        </GeneralApp>
    );
};
