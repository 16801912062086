import React from "react";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import Autocomplete, {
    createFilterOptions,
} from "@material-ui/lab/Autocomplete";
// import { makeStyles } from "@material-ui/core/styles";

import { getProperty, TYPE_FIELD } from "../../../../helpers/utils";
import { postMedicinas } from "../../../../services/medicamentos-service";
import { ContactSupportOutlined } from "@material-ui/icons";
const filter = createFilterOptions();

const filterMedicines = (options, params) => {
    const filtered = filter(options, params);

    if (params.inputValue !== "") {
        filtered.push({
            inputValue: params.inputValue,
            medicamento: `Agregar "${params.inputValue}"`,
        });
    }

    return filtered;
};

const labelOptions = (option) => {
    if (typeof option === "string") return option;
    if (option.inputValue) return option.inputValue;
    return option.medicamento;
};

// const useStyles = makeStyles({
//     root: {
//         width:200,
//         paddingBottom:"15px",
        
//     },
//     inputRoot:{
//         borderRadius:"25px",
//     }
    
// });

export default function FreeSoloCreateOptionDialog(props) {
    // const classes = useStyles();
    const [open, toggleOpen] = React.useState(false);
    const { form, name, handleChange } = props;
    const [value, setValue] = React.useState(getProperty(form, name));
    const [medicamentoValue, setmedicamentoValue] = React.useState({
        medicamento: "",
    });
    const eventArfititial = (newValue) => ({
        target: {
            name,
            value: newValue?.medicamento ?? "",
        },
    });
    const handleClose = () => {
        setmedicamentoValue({
            medicamento: "",
        });
        toggleOpen(false);
    };
    const removeWhiteSpaces = () =>
        setmedicamentoValue((medy) => ({
            medicamento: medy.medicamento.trim(),
        }));

    const handleSubmit = (event) => {
        removeWhiteSpaces();
        const e = eventArfititial(medicamentoValue);
        //El post
        postMedicinas(medicamentoValue)
            .then((response) => {
                setValue(medicamentoValue.medicamento);
                handleChange(e, form.row);
                handleClose();
            })
            .catch((response) => {
                alert("No yey :c");
                console.log(response);
            });
    };
    const handleChangeMedicine = (newValue) => {
        if (typeof newValue === "string") {
            // timeout to avoid instant validation of the dialog's form.
            setTimeout(() => {
                toggleOpen(true);
                setmedicamentoValue({
                    medicamento: newValue,
                });
            });
        } else if (newValue?.inputValue) {
            toggleOpen(true);
            setmedicamentoValue({
                medicamento: newValue.inputValue,
            });
        } else setValue(newValue);
    };

    return (
        <React.Fragment>
            {/* <Autocomplete
                className= {`${classes.root} ${classes.inputRoot}`}
                id="combo-box-demo"
                size="small"
                options={[]}
                getOptionLabel={(option) => option.title}                
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label="Sustancia activa"
                        variant="outlined"
                    />
                )}
            /> */}

            <Autocomplete
                fullWidth={true}
                value={value}
                onChange={(e, newValue) => {
                    const event = eventArfititial(newValue);
                    handleChange(event, form.row);
                    handleChangeMedicine(newValue);
                }}
                filterOptions={(options, params) =>
                    filterMedicines(options, params)
                }
                options={props.medicamentos}
                getOptionLabel={(option) => labelOptions(option)}
                selectOnFocus
                clearOnBlur
                handleHomeEndKeys
                renderOption={(option) => option.medicamento}
                freeSolo
                renderInput={(params) => (
                    <TextField {...params} label="" variant={TYPE_FIELD} />
                )}
            /> 

            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="form-dialog-title"
            >
                <form>
                    <DialogTitle id="form-dialog-title">
                        Agregar un medicamento nuevo
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            ¿No encuentras el medicamento que buscas? Agrégalo !
                        </DialogContentText>
                        <TextField
                            fullWidth={true}                            
                            autoFocus
                            value={medicamentoValue.medicamento}
                            onChange={(event) =>
                                setmedicamentoValue({
                                    ...medicamentoValue,
                                    medicamento: event.target.value,
                                })
                            }
                            label="Descripción"
                            type="text"
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose} color="primary">
                            Cancelar
                        </Button>
                        <Button onClick={handleSubmit} color="primary">
                            Guardar
                        </Button>
                    </DialogActions>
                </form>
            </Dialog>
        </React.Fragment>
    );
}
