import React from "react"
import Collapse from "@material-ui/core/Collapse"
import ItemWithinLink from "./ItemWithinLink"
import ItemNormal from "./ItemNormal"
import List from "@material-ui/core/List"
import Divider from '@material-ui/core/Divider'
import {ExpandLess,ExpandMore} from "@material-ui/icons/";
const LessOrMoreIcon = props => {    
    return props.open ? <ExpandLess /> : <ExpandMore />
}
export default function ItemCollapsed(props){
    const [open, setOpen] = React.useState(false);
    const handleClick = () => {
        setOpen(!open)
    }    
    
    return (
        <React.Fragment>            
            <ItemWithinLink item={props.item} onClick={handleClick} open={open} >
                <LessOrMoreIcon open={open}/>
            </ItemWithinLink>            
            <Divider />
            <Collapse in={open} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    {props.item.sub.map(e => <ItemNormal key={`${e.id}item`} to={e.route} item={e}/>)}
                </List>
                <Divider />
            </Collapse>
            
        </React.Fragment>
    );
};
