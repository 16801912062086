import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';

const useStyles = makeStyles((theme) => ({
  root: {
    // position:"fixed",
    // bottom:0,
    // left:0,
    textAlign: "center",    
    paddingTop: "1.5em",
    paddingBottom: "1.5em",
    width:"100%",
    marginTop:"auto",
  },
}));

export default function Variants() {
  const classes = useStyles();

  return (    
      <Paper variant="outlined" className={classes.root}>
      © 2020 Clinica Gilces-Todos los derechos reservados
      </Paper>    
  );
}

