import { useState } from 'react'

export const usePaginator = (pages = 0, rows = 5) => {
    const [page, setPage] = useState(pages);
    const [rowsPerPage, setRowsPerPage] = useState(rows)

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    return {page, rowsPerPage, handleChangePage, handleChangeRowsPerPage};
}
