import { Container, Typography } from '@material-ui/core'
import React from 'react'
import { useParams } from 'react-router'
import { LocalizacionLesionesForm } from '../../core/Forms/Admision/LocalizacionLesionesForm'
import { MotivoForm } from '../../core/Forms/Admision/MotivoForm'
import DatosClinicoForm from '../ClinicalData/DatosClinicoForm'
import DatosPersonalesForm from '../PersonalData/DatosPersonalesForm'

export const RegistroAdmisionView = () => {
    const { room } = useParams()
    return (
        <Container>
            <Typography variant="h4" component="h1" gutterBottom>
                Registro de admision Habitacion: { room }
            </Typography>
            
            <DatosPersonalesForm/>

            <Typography variant="h4" component="h1" gutterBottom>
                Inicio de atención y motivo.
            </Typography>
            <MotivoForm/>
            <Typography variant="h4" component="h1" gutterBottom>
                Accidente, violencia, intoxicación, envenenamiento o quemadura.
            </Typography>
            <Typography variant="h4" component="h1" gutterBottom>
                Antecedentes personales y familiares.
            </Typography>
            <Typography variant="h4" component="h1" gutterBottom>
                Enfermedad actual y revisión de sistemas.
            </Typography>
            <Typography variant="h4" component="h1" gutterBottom>
                Caracteristicas del dolor.
            </Typography>
            <Typography variant="h4" component="h1" gutterBottom>
                Signos vitales, mediciones y valores.
            </Typography>
            <DatosClinicoForm/>

            <Typography variant="h4" component="h1" gutterBottom>
                Examen Físico.
            </Typography>
            <Typography variant="h4" component="h1" gutterBottom>
                Localización de lesiones.
            </Typography>
            <LocalizacionLesionesForm/>
            <Typography variant="h4" component="h1" gutterBottom>
                Emergencia obstetrica.
            </Typography>
            <Typography variant="h4" component="h1" gutterBottom>
                Solicitud de examenes.
            </Typography>
            <Typography variant="h4" component="h1" gutterBottom>
                Diagnostico de ingreso.
            </Typography>
            <Typography variant="h4" component="h1" gutterBottom>
                Plan de tratamiento.
            </Typography>
            <Typography variant="h4" component="h1" gutterBottom>
                Alta.
            </Typography>
        </Container>
    )
}
