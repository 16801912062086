import DateFnsUtils from "@date-io/date-fns";
import { TextField } from "@material-ui/core";
import { KeyboardTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import React, { useState } from "react";
import { CheckboxGroup } from "../Checkbox/CheckboxGroup";
const items = [
    {        
        name:"trauma",
        label:"Trauma",
    },
    {   
        name:"causaClinica",
        label:"Causa Clínica",
    },
    {   
        name:"causaObstetrica",
        label:"Causa Obstétrica",
    },
    {   
        name:"causaQuirurgica",
        label:"Causa Quirúrgica",
    },
    {   
        name: "notifyPolice",
        label:"Notificación a la policía",
    },
    {   
        name:"otro",
        label:"Otro",
    },
]
const initialState = {
    causaQuirurgica: false,
    causaObstetrica: false,
    causaClinica: false,
    notifyPolice: false,
    trauma:false,
    otro:false,
}
export const MotivoForm = () => {
    const [selectedDate, setSelectedDate] = React.useState(
        new Date("2014-08-18T21:11:54")
    );
    
    const [motivo, setMotivo] = useState(initialState);
    const handleDateChange = (date) => {
        setSelectedDate(date);
    };
    const handleChange = ({ target }) => {
        setMotivo({ ...motivo, [target.name]: target.checked });
    };
    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardTimePicker
                id="admision-hour"
                label="Hora"
                value={selectedDate}
                onChange={handleDateChange}
                KeyboardButtonProps={{
                    "aria-label": "change time",
                }}
            />
            <TextField label="Grupo sanguíneo/factor Rh" variant="outlined"/>
            <CheckboxGroup form={motivo} items={items} handleChange={handleChange}/>
            {motivo.otro &&  <TextField label="Motivo"/>}
        </MuiPickersUtilsProvider>
    );
};
