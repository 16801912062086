import SettingsIcon from "@material-ui/icons/Settings";
import PeopleIcon from "@material-ui/icons/People";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import PermIdentityIcon from '@material-ui/icons/PermIdentity';
import StorageIcon from "@material-ui/icons/Storage";
import LocalHospitalIcon from "@material-ui/icons/LocalHospital";
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import { cePath } from "../../helpers/utils";

let i = 0
const menuAdmin = [
    {
        id: i++,
        nombre: "Enfermero",
        icon: <PeopleIcon />,        
        sub: [
            {
                id: i++,
                nombre: "Datos Personales",
                icon: <PermIdentityIcon />,                    
                route: `${cePath}/datos_personales`,
            },
            {
                id: i++,
                nombre: "Datos Clínico",

                icon:<StorageIcon />,
                route: `${cePath}/datos_clinico`,
            },
        ],
    },
    {
        id:i++,
        nombre:"Médico",
        icon: <LocalHospitalIcon />,
        sub:[
            {
                id: i++,
                nombre: "Historial Clínico",
                icon: <AssignmentIndIcon />,
                route: `${cePath}/historial_clinico`,
            },

        ]
    }, 
    
    {
        id:i++,
        nombre:"Laboratorio",
        icon: <LocalHospitalIcon />,
        sub:[
            {
                id: i++,
                nombre: "Examen",
                icon: <AssignmentIndIcon />,
                route: `${cePath}/examen`,
            },

        ]
    },    

    {
        id: i++,
        nombre: "Configuración",
        icon: <SettingsIcon />,
        route: `${cePath}/configuracion`,
    },
    {
        id: i++,
        nombre: "Cerrar Sesión",
        icon: <ExitToAppIcon />,
        route: "/login",
    },
]
export default menuAdmin