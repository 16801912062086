import React, { useState, useEffect } from "react";
import getDatosClinicos from "../../../services/signos-vitales-service";
import DatoClinicoCard from './DatoClinicoCard'
export default function DatosClinico(props) {    
    const [data,setData] = useState({})    
    useEffect(() => {        
        if (props.id) {
            getDatosClinicos(props.id)                
                .then((response) => {                    
                    setData(response.data)
                })
                .catch((error) => {
                    console.log(error);
                });
        }                
    }, [props]);    
    return <DatoClinicoCard data={data}/>            
}
