import React from "react";
import Table from "@material-ui/core/Table";
import TableContainer from "@material-ui/core/TableContainer";
import Paper from "@material-ui/core/Paper";
import { TableHeader } from "./TableHeader";
import { TableContent } from "./TableContent";
export default function BasicTable(props) {    
    const {headers,rows} = props;
    return (
        <TableContainer component={Paper}>
            <Table aria-label="simple table">
                <TableHeader headers={headers}/>
                <TableContent rows={rows} rowsPerPage={0} {...props}/>
            </Table>
        </TableContainer>
    );
}
