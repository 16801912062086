import { getFichaMedicaPacienteByCedula } from "../../services/paciente-service";
import { medicalRecord } from "./types";
import { setUiMessage, uiLoading } from "./ui";

export const medicalRecordActive = (record) => ({
    type: medicalRecord.medicalRecordActive,
    payload:record,
});
export const getMedicalRecordByCedula = (cedula) => dispatch =>{
    dispatch(uiLoading(true)); 
    return new Promise((resolve,reject) =>{
        getFichaMedicaPacienteByCedula(cedula)
            .then(response =>{
                resolve(response)
            })
            .catch(err =>{
                const message = err?.response?.data?.errors || err.toString() || "Error inesperado";
                dispatch(setUiMessage(message));
                reject(message)
            })
            .finally(()=> dispatch(uiLoading(true)))

    })
}

export const medicalRecordClear = () => ({
    type: medicalRecord.medicalRecordActive,
    payload:{},
});

