export const getProperty = (form,name) => {    
    if( typeof form === "string") return form
    for (let property in form) {            
        if (name === property){
            if(typeof form[name] === "object")
                return form[name].telefono; 
            return form[name];
        } 
    }    
};
export const getHeaders = (headers) => headers.map(header => ({label: header}))
export const cePath = "/app/consulta_externa";
export const enfermeriaPath = "/app/enfermeria";
export const getTheme = (theme, name, ext = "png",name2=`${name}-dark`) => theme.palette.type === "light" ?
    `/assets/${name}.${ext}`: `/assets/${name2}.${ext}`

export const formDefault = {
    nombres: "",
    apellidoMaterno: "",
    apellidoPaterno: "",
    cedula: "",
    domicilio: "",
    sexo: "M",
    fechaNacimiento: new Date(),
    telefonos: ["",""],
};
export const formErrorsPersonal = {
    telefono:Array(2)
};
//filled, outlined,standard
export const TYPE_FIELD = "outlined"