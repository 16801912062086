import React, { useEffect, useState } from 'react'
import Table from "@material-ui/core/Table";
import TableContainer from "@material-ui/core/TableContainer";
import Paper from "@material-ui/core/Paper";
import { usePaginator } from '../../../hooks/usePaginator';
import { TableFoot } from '../../core/Table/TableFoot';
import { TableHeader } from "../../core/Table/TableHeader";
import { TableContent } from "../../core/Table/TableContent";

import paciente from '../../../redux/actions/paciente';
import { setDialogView } from '../../../redux/actions/ui';
import { medicalRecordActive } from '../../../redux/actions/fichaMedica';

import { connect } from 'react-redux';
import { DiagnosticButton,LabButton } from '../../core/Buttons/TableButtons';
import UltimaFichaMedica from '../../core/UltimaFichaMedica';
import { getFichaMedicaPacienteByCedula } from '../../../services/paciente-service';
import { getHeaders } from '../../../helpers/utils';
import { getExamFile } from '../../../services/medico-service';
import fileDownload from 'js-file-download';


const headers = getHeaders([
    "ID Ficha Medica",
    "Fecha",
    "Enfermero/a",
    "Examen de laboratorio",
    "Ver Diagnóstico",
]);

const MedicalHistory = (props) => {
    const { dialogView } = props.UI;
    const { cedula } = props.paciente;
    const { page, rowsPerPage, handleChangePage, handleChangeRowsPerPage } = usePaginator();
    const [fichas, setFichas] = useState([])
    const handleExamFile = (data) =>{
        const adapterFileName = data.examenLaboratorio
        getExamFile(adapterFileName)
        .then((res)=>{
            fileDownload(res.data,data.examenLaboratorio + ".pdf")
        })
        .catch(err =>{
            console.log(err)
        })
    }
    const handleClick = (row) =>{
        props.setMedicalRecordActive({
            id: row.id,
            medico:row.medico,
            evolution: row.evolution,
            motivo:row.motivo,
            diagnostico: row.diagnostico,
            fechaIngreso: row.fecha,
            tratamiento:row.Fila,
        })
        props.opened(true);
    }
    useEffect(() => {
        getFichaMedicaPacienteByCedula(cedula)
            .then(response =>{
                console.log(response)
                setFichas(response.map(responsy =>({
                    ...responsy,
                    examenLaboratorio:responsy.examenLaboratorio ? <LabButton onClick={() => handleExamFile(responsy)}/> : "NA"
                })))
            })
            .catch(err =>{
                alert(err)
            })
    }, [cedula])


    return (
        <>
            <TableContainer component={Paper}>
                <Table aria-label="Tabla de fichas médica">
                    <TableHeader headers={headers}/>
                    <TableContent rows={fichas} 
                        fieldsHidden={["Fila", "motivo", "evolution","medico","diagnostico"]}
                        rowsPerPage={rowsPerPage} 
                        page={page}
                        components={[
                            [{handleE: handleClick, Component: DiagnosticButton}],
                            // [{handleE: handleExamFile, Component: DiagnosticButton}],
                        ]}
                    />
                    <TableFoot count={fichas.length} 
                        page={page} handleChangePage={handleChangePage}
                        rows={rowsPerPage} handleChangeRowsPerPage={handleChangeRowsPerPage}
                    />
                </Table>
            </TableContainer>
            {dialogView && 
                <UltimaFichaMedica/>
            }
        </>
    );
    
}
const mapStateToProps = (state) => ({
    paciente: state.paciente.query,
    UI: state.UI,
});
const mapDispatchToProps = (dispatch) => ({
    changePacienteQuery: (query) => dispatch(paciente(query)),
    opened: (check) => dispatch(setDialogView(check)),
    setMedicalRecordActive: (medicalRecord) => dispatch(medicalRecordActive(medicalRecord)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MedicalHistory);
