import Grid from "@material-ui/core/Grid"
import LoginForm from "../../core/Forms/LoginForm"
import TitleView from '../../core/TitleView'
import { makeStyles } from '@material-ui/core/styles'
import {useRef, useEffect} from "react"
import loginAuth from "../../../redux/actions/auth";
import {clearPaciente} from "../../../redux/actions/paciente";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "../../../hooks/useForm"
import { cePath } from "../../../helpers/utils"
const useStyles = makeStyles((theme) => ({
    root: {
        position:"relative",
        top:"-1em",
    },
  }));
const login = {
    username: "",
    password: "",        
}

export default function Login(props){
    const { isLoggedIn, user } = useSelector((state) => state.auth);
    const dispatch = useDispatch();
    const classes = useStyles();
    const isMountedRef = useRef(true);
    useEffect(()=>{
        dispatch(clearPaciente())
    },[dispatch])

    useEffect( () => () => {
            isMountedRef.current = false;
        },[]
    );

    const [form, handleChange] = useForm(login);
    
    const handleSubmit = e => {
        e.preventDefault();
        dispatch(loginAuth(form))
            .then(() => {
                if(user.rol)
                    props.history.push(cePath);
                else
                    props.history.push("/app");
                window.location.reload();
            })
            .catch(error => {
                //Para que no ejecute si el componente esta desmounted
                if (isMountedRef.current){
                    console.log(error)
                }
            })
    };
    return(        
        <Grid container justify="center" alignItems="center">
            <TitleView title="Iniciar Sesión"/>
            <Grid item sm={12} md={12} lg={12} className={classes.root}>
                <LoginForm isLoggedIn={isLoggedIn} form={form}
                    handleSubmit={handleSubmit} handleChange={handleChange}
                />
            </Grid>
        </Grid>
    )
}