import React from "react";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import getSizes from '../../../helpers/sizes'
import { getProperty, TYPE_FIELD } from '../../../helpers/utils'
// import { myStyles } from "./textFieldStyles";
// import clsx from 'clsx';
export default function ClinicalTextField(props) {    
    const sizes = getSizes(props)    
    const { form, name, label } = props;
    const { handleChange, ...withoutHandle } = props;
    const typeDefault = props.type ?? "text"
    // const classes = myStyles()
    return (
        <React.Fragment>
            <Grid item lg={sizes.lg} sm={sizes.sm} xs={sizes.xs}>
            <TextField
                {...withoutHandle}
                required={props.required ?? true}
                fullWidth={props.fullWidth ?? true}
                name={name}
                value={getProperty(form, name)}
                
                onChange={handleChange}
                label={label}
                type={typeDefault}
                variant={TYPE_FIELD}
                // className={clsx(classes.root)}
            />
            </Grid>
        </React.Fragment>
    );
}
