import React from "react";

import RadioGroup from "@material-ui/core/RadioGroup";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";

export const RadioField = ({children, handleChange, value, title, name,row,required}) => {
    return (
        <FormControl component="fieldset" fullwidth required={required ?? false}>
            <FormLabel component="legend">{title}</FormLabel>
            <RadioGroup
                aria-label={name}
                name={name}
                value={value}
                onChange={handleChange}
                row={row ?? false}
            >
                {children}
            </RadioGroup>
        </FormControl>
    );
};
