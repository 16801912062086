import Visibility from "@material-ui/icons/Visibility";
import TypeInput from './TypeInput'
import FormControl from "@material-ui/core/FormControl";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import InputLabel from "@material-ui/core/InputLabel";
import {useState} from 'react';
import { TYPE_FIELD } from '../../../helpers/utils';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
export default function PasswordField(props) {
    const [showPassword,setShowPassword] = useState(false)
    const handleClick = () =>{
        setShowPassword(!showPassword)
    }
    const handleMouseDownPassword = event => {
        event.preventDefault();
    };
    return (
        <FormControl variant={TYPE_FIELD} fullWidth required>
            <InputLabel htmlFor="outlined-adornment-password">
                Password
            </InputLabel>
            <TypeInput
                id="outlined-adornment-password"
                type={showPassword ? "text" : "password"}
                value={props.value}
                onChange={props.handleChange}
                name={props.name}
                endAdornment={
                    <InputAdornment position="end">
                    <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClick}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                    >
                        {showPassword ? (
                            <Visibility />
                        ) : (
                            <VisibilityOff />
                        )}
                    </IconButton>
                </InputAdornment>

                }
                startAdornment={
                    <InputAdornment position="start"><VpnKeyIcon/></InputAdornment>
                }
                labelWidth={100}
            />
        </FormControl>
    );
}
