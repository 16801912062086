import React, { useRef, useState } from "react";
import Grid from "@material-ui/core/Grid";
import Progress from "../../core/Progress";
import TitleView from "../../core/TitleView";
import { useDispatch, connect } from "react-redux";
import DatosPersonalesForm from "./DatosPersonalesForm";
import postDatosPersonales from "../../../services/datos-personales";
import AlertDialog from "../../core/AlertDialog";
import { formErrorsPersonal } from "../../../helpers/utils";
import { useEffect } from "react";
import { clearPersonalData } from "../../../redux/actions/paciente";
function DatosPersonalesView(props) {
    const { isLoad,form,} = props
    const dispatch = useDispatch();
    const [formErrors,setFormErrors] = useState(formErrorsPersonal);
    const [title, setTitle] = useState("");
    const ref = useRef(dispatch)
    useEffect(() => {
        ref.current(clearPersonalData())
    }, [])

    const handleSubmit = (e) => {
        e.preventDefault();
        dispatch(postDatosPersonales(form))
            .then(() => {
                setTitle("Registro exitoso");
            })
            .catch((error) => {
                setTitle("Registro fallido");
                setFormErrors(errorOriginal => ({
                    ...errorOriginal,
                    ...error,
                }));
                
            });
    };
    return (
        <Grid container justify="center" spacing={3}>
            <TitleView title="Datos Personales" />
            <Grid item lg={12} sm={12} xs={12}>
                <AlertDialog
                    title={title}
                />
                <DatosPersonalesForm handleSubmit={handleSubmit}
                    formErrors={formErrors} setFormErrors={setFormErrors}
                />
            </Grid>
            {isLoad && <Progress />}
        </Grid>
    );
}
const mapStateToProps = (state) => ({
     isLoad: state.UI.loading,
     form: state.paciente.personalData,
});
export default connect(mapStateToProps)(DatosPersonalesView);