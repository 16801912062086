import axios from "axios";
import API_URL from "./api-clinica-url";
import authHeader from "./auth-header";

export const getDatosClinicos = (id) =>
    new Promise((resolves, rejects) => {
        axios
            .get(`${API_URL}/api/signos_vitales/${id}/`, { headers: authHeader() })
            .then((response) => resolves(response))
            .catch((response) => rejects(Error(response)));
    });
export default getDatosClinicos