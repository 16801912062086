import React from "react";
import { Router, Switch } from "react-router-dom";
import LoginView from "../components/views/Login/LoginView";
import { Redirect, Route } from "react-router";
import { history } from "../helpers/history";
import MainMenuRouter from "./MainMenuRouter";
export const AppRouter = () => {
    return (
        <Router history={history}>
            <div>
                <Switch>
                    <Route exact path="/login" component={LoginView} />
                    <Route path="/app" component={MainMenuRouter} />
                    <Redirect to="/login" />
                </Switch>
            </div>
        </Router>
    );
};
