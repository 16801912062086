import React from "react";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Container, Typography } from "@material-ui/core";
import { getTheme } from "../../../helpers/utils";
const useStyles = makeStyles((theme) => ({
    root: {
        display:"flex",
        alignItems:"center",
        justifyContent:"space-around",
        [theme.breakpoints.down('md')]: {
            flexWrap:"wrap"
        },
    },
    image:{
        maxWidth:"50%",
        [theme.breakpoints.down('md')]: {
            maxWidth:"100%",
            marginBottom: theme.spacing(2)
        },
        objectFit:"cover",
    }
}));

export const TableContent = (props) => {
    const classes = useStyles();
    const theme = useTheme();
    const typeImage =  getTheme(theme,"empty-table","png")
    const { rows =[], rowsPerPage = 1, page = 0, fieldsHidden = [] } = props;
    
    return (
        <TableBody>
            {(rowsPerPage > 0
                ? rows.slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage
                  )
                : rows
            ).map((row, index) => (
                <TableRow key={`${row.id} ${fieldsHidden.length}`}>
                    {Object.entries(row).map((key, value) => {
                        
                        let flag = false
                        fieldsHidden.forEach( h =>{
                            if(h === key[0])
                                flag = true;
                        })
                        if(!flag)
                            return <TableCell key={`${key[1]} ${value} ${index}`} align="center">{key[1]}</TableCell>
                        return false;
                    })}
                    {props.components && props.components.map((component,index) =>(
                        <TableCell align="center" key={row.name}>
                            {component.map((c) =>
                                <c.Component
                                    onClick={e=> c.handleE(row,index)}
                                />
                            )}
                        </TableCell>
                    ) )}
                </TableRow>
                
            ))}
            {rows.length < 1 &&(
                <TableRow>
                    <TableCell colSpan={6}>
                        <Container className={classes.root}>
                            <img src={typeImage} alt="is empty :(" className={classes.image}/>
                            <Typography paragraph>
                                No hay contenido en la tabla :/
                            </Typography>
                        </Container>
                    </TableCell>
                </TableRow>
            )}
        </TableBody>
    );
};
