import axios from 'axios'
import API_URL from "./api-clinica-url"
import authHeader from './auth-header'
import { setDialog, setDialogTurn, setUiMessage, uiLoading } from '../redux/actions/ui';
import paciente, { clearPersonalData } from '../redux/actions/paciente';
import { format } from "date-fns"
const toDateApi = ({fechaNacimiento}) => format(new Date(fechaNacimiento),'yyyy-MM-dd')
const getMessage = (error) => (error?.response?.data && error?.response?.detail
    ) || error.response.data ||
    error.toString();

const nombres = (form) =>
        `${form.nombres} ${form.apellidoPaterno} ${form.apellidoMaterno}`;
const formatForm = (form) =>({
    ...form,
    telefonos: form.telefonos.map(tlf =>tlf.telefono),
    fechaNacimiento: toDateApi(form)
})
export const postDatosPersonales = (newForm) => dispatch =>{
    dispatch(uiLoading(true));
    const form = formatForm({...newForm}) 
    return new Promise((resolve, reject) =>{
        axios.post(`${API_URL}/api/pacientes/`, form,{
            headers: authHeader()
        })    
        .then(() =>{
            const message = `Los datos de ${form.nombres} se ha ingresado correctamente`
            dispatch(
                paciente({
                    cedula: form.cedula,
                    nombre: nombres(form),
                    alergias: "",
                    alergia: "",
                })
            );
            dispatch(setUiMessage(message));
            resolve();
        })
        .catch(error=>{
            const message = getMessage(error);
            dispatch(setUiMessage("Datos ingresados incorrectamente"));
            reject(message);
        })
        .finally(()=>{
            dispatch(uiLoading(false));
            dispatch(setDialog(true));
        })
    })
}

export const putDatosPersonales = (newForm, cedula) => dispatch =>{
    dispatch(uiLoading(true))
    const form = formatForm({...newForm}) 
    return new Promise((resolve, reject) =>{
        axios.put(`${API_URL}/api/pacientes/${cedula}/`, form,{
            headers: authHeader()
        })
        .then(() =>{
            const message = `Los datos de ${form.nombres} se ha modificado correctamente`
            dispatch(
                paciente({
                    cedula: form.cedula,
                    nombre: nombres(form),
                    alergias: "",
                    alergia: "",
                })
            );
            dispatch(clearPersonalData())
            dispatch(setUiMessage(message));
            resolve();
        })
        .catch(error=>{
            // const message = getMessage(error);
            dispatch(setUiMessage("Los datos no se modificadon :("));
            reject();
        })
        .finally(()=>{
            dispatch(setDialogTurn(false))
            dispatch(uiLoading(false));
            dispatch(setDialog(true));
        })
    })
}

export default postDatosPersonales