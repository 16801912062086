import { FormGroup } from '@material-ui/core'
import React from 'react'
import { CheckboxItem } from './CheckboxItem'

export const CheckboxGroup = ({items,handleChange, form}) => {
    return (
        <FormGroup row>
            {items.map(item => <CheckboxItem checked={form[item.name]}
                handleChange={handleChange}  name={item.name} label={item.label}/>)}
        </FormGroup>
    )
}