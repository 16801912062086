import axios from "axios";
import { setDialog, setUiMessage, uiLoading } from "../redux/actions/ui";
import API_URL from "./api-clinica-url";
import authHeader from "./auth-header";

export const postDatosClinico = (form) => dispatch =>{
    dispatch(uiLoading(true));
    return new Promise((resolve, reject) => {
        axios
            .post(`${API_URL}/api/datos_clinicos/`, form, {
                headers: authHeader(),
            })
            .then(() => {
                const message = `Los datos se ha ingresado correctamente`;
                dispatch(setUiMessage(message));
                resolve();
            })
            .catch((err) => {
                const message = (err?.response?.data?.errors) ||
                    `Los datos se ha ingresado incorrectamente` || err.toString();
                dispatch(setUiMessage(message));
                reject();
            })
            .finally(()=>{
                dispatch(uiLoading(false));
                dispatch(setDialog(true));
            });
    });
}