import React from "react";
import { CardContent, Typography } from "@material-ui/core";
import { RoomCard } from "./RoomCard";
import DescriptionAlerts from "../../Alert";

export const NurseRoomCard = ({id}) => {
    return (
        <RoomCard id={id}>
            <CardContent>
                <Typography gutterBottom variant="body2">Paciente: Alex Cuenca M</Typography>
                <DescriptionAlerts description="Le toca dar paracetamol"/>
                <DescriptionAlerts type="warning" description="Le dió ansiedad al paciente"/>
                <DescriptionAlerts type="error" description="Es emelecista"/>
            </CardContent>
        </RoomCard>
    );
};
