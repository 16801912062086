import { Button, Card, CardActions,
    CardHeader, Fab, makeStyles } from '@material-ui/core'
import React from 'react';
import FavoriteIcon from '@material-ui/icons/Favorite';
import { useHistory } from 'react-router';
import { enfermeriaPath } from '../../../../helpers/utils';

const useStyles = makeStyles(() => ({
    cardActions: {
        display:"flex",
        justifyContent:"space-between",
    },
    addButton:{
        marginLeft: 'auto',
    }
}));

export const RoomCard = ({children, id}) => {
    const classes = useStyles();
    const history = useHistory();
    const clickHojaEvo = () =>{
        history.push(`${enfermeriaPath}/${id}/hoja_evo`)
    }
    const clickKardex = ( ) =>{
        history.push(`${enfermeriaPath}/${id}/kardex`)
    }
    return (
        <Card>
            <CardHeader
                title={`Habitacion ${id}`}
                subheader="Ocupado"
            />
            {children}
            <CardActions className={classes.cardActions}>
                <div>
                    <Button color="primary" onClick={clickHojaEvo} variant="text">Hoja Evo</Button>
                    <Button color="primary" onClick={clickKardex} variant="text">Kardex</Button>
                </div>
                <Fab color="secondary" aria-label="alta" className={classes.addButton}>
                    <FavoriteIcon/>
                </Fab>
            </CardActions>
        </Card>
    )
}
