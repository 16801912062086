export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT = "LOGOUT";

export const OPEN = "OPEN";
export const paciente = {
    clearPaciente: "[PATIENT] Clear patient",
    clearPersonalData: "[PATIENT] Clear personal data patient",
    setPaciente: "[PATIENT] Set patient",
    setDataPersonalPaciente:"[PATIENT] Set personal data patient",
    setTelephones: "[PATIENT] Set telephones",
    loginPatientSuccess: "[PATIENT] Login success of Laboratory exams",
};

export const NOTIFIES = "NOTIFIES";

export const ui = {
    nightMode: "[UI] Night mode",
    setMessage: "[UI] Set Message",
    clearMessage: "[UI] Clear Message",
    loading: "[UI] Loading",
    dialogMajor: "[UI] Open/Close dialog major",
    dialogView: "[UI] Open/Close dialog view",
    cargarView: "[UI] Open/Close examen view",
    dialog: "[UI] Open/Close dialog",
}
export const medicalRecord = {
    medicalRecordActive: "[RECORD] Set medical record",
    medicalRecordClear: "[RECORD] Clear medical record",
}

export const ARCHIVO = "ARCHIVO";