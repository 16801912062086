import axios from "axios";
import API_URL from "./api-clinica-url";
import authHeader from "./auth-header";

export const getPacientes = () =>
    new Promise((resolves, rejects) => {
        axios
            .get(`${API_URL}/api/pacientes/`, { headers: authHeader() })
            .then((response) => resolves(response.data))
            .catch((response) => rejects(Error(response)));
    });
export const getExamsPacients = () =>
    new Promise((resolves, rejects) => {
        axios
            .get(`${API_URL}/api/laboratorio/`, { headers: authHeader() })
            .then((response) => resolves(response.data))
            .catch((response) => rejects(Error(response)));
    });
export const postExamPatient = (form) =>
    new Promise((resolves, rejects) => {
        axios
            .post(`${API_URL}/api/laboratorio/`,form, { headers: authHeader()})
            .then((response) => {
                resolves(response.data)
            })
            .catch((response) => rejects(response)) 
    });

export const getPaciente = (cedula) =>
    new Promise((resolves, rejects) => {
        axios
            .get(`${API_URL}/api/pacientes/${cedula}/`, { headers: authHeader() })
            .then((response) => resolves(response.data))
            .catch((response) => rejects(Error(response)));
    });

export const getFichaMedicaPacienteById = (id) =>
    new Promise((resolves, rejects) => {
        axios
            .get(`${API_URL}/api/fichas_medicas/?id=${id}`, { headers: authHeader() })
            .then((response) => resolves(response.data))
            .catch((err) => rejects(err.response));
    });
export const getFichaMedicaPacienteByCedula = cedula =>
new Promise((resolves, rejects) => {
    axios
        .get(`${API_URL}/api/fichas_medicas/?cedula=${cedula}`, { headers: authHeader() })
        .then((response) => resolves(response.data))
        .catch((err) => rejects(err.response));
    });
export default getPacientes;
