// import clinica from "../../"
import Hidden from '@material-ui/core/Hidden';
import withWidth from '@material-ui/core/withWidth';
import { useTheme } from '@material-ui/core/styles';
import { getTheme } from '../../helpers/utils';


const imagen = {
    root:{
        height:"100vh",
        minHeight:"650px",
        width:"100%",
        
        objectFit:"cover",
        display:"block",
    }
}
const Imagen = () => {
    const theme = useTheme();
    const mode = getTheme(theme,"clinica-medica-adulta", "jpg",
    "black-doctor-america-covid"
    )
    return(        
        <Hidden mdDown>
            <>
                <img src={mode} alt="homepage of system clinic" style={imagen.root}/>
            </>
        </Hidden>
    )
}

export default withWidth()(Imagen);