import menuAdmin from "./MenuAdmin";
import menuMedico from "./MenuMedico"
import menuEnfermero from "./MenuEnfermero"
import menuLaboratorio from "./MenuLaboratorio";
export default function tipoMenu(tipo){    
    if(tipo === "Administrador(a)")
        return menuAdmin
    else if(tipo === "Médico")
        return menuMedico
    else if(tipo === "Enfermero(a)")
        return menuEnfermero
    else if(tipo === "Laboratorio")
        return menuLaboratorio
}
