import { makeStyles } from "@material-ui/core";
import React, { useState } from "react";
import { Stage, Layer, Circle } from "react-konva";
import { Image } from 'react-konva';
import useImage from 'use-image';

const dimensions = {
    width: 300,
    heihgt: 300,
}
const useStyles = makeStyles(() => ({
    root:{
        background: "white",
        ...dimensions,
    }
}));
const url = "https://konvajs.org/assets/yoda.jpg"
export const LocalizacionLesionesForm = () => {
    const classes = useStyles();
    const [circles, setCircles] = useState([])
    const [image] = useImage(url)
    const addCircles = (e) => {
        const stage = e.target.getStage();
        //x and y on mouse position :D
        const point = {...stage.getPointerPosition(),id: circles.length + 1};
        setCircles([...circles, point])
    }

    return (
        <div>
            <Stage onClick={addCircles} width={dimensions.width} 
                height={dimensions.heihgt} className={classes.root}>
                <Layer>
                    <Image image={image} />
                    {circles.map(circle => <Circle 
                        key={`${circle.x}-${circle.id}`}
                        x={circle.x} y={circle.y}
                        width={30} height={30} fill="red" />)}
                    
                </Layer>
            </Stage>
        </div>
    );
};
