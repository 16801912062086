import { useState } from 'react'

export const useForm = (form = {}) => {
    const [values, setValues] = useState(form)

    const reset =(newValue = form)=>{
        setValues(newValue);
    }
    const handleChange = ({ target }) => {        
        setValues({
            ...values,
            [target.name]: target.value,
        });
    };
    return [values, handleChange,reset]
}
