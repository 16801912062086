import InfoCard from './InfoCard'
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
export default function DatoClinicoCard(props){
    const {data} = props
    return(        
        <Grid container>
            <Grid item lg={12} sm={12} xs={12}>
                <Card>
                    <CardHeader                        
                        title="Datos Clínicos"                        
                    />
                    <CardContent>
                        <Grid container justify="flex-start" >
                            <InfoCard title="F.C:" value={data?.fc}/>                            
                            <InfoCard title="SPO:" value={data.spo}/>
                            <InfoCard title="P.A:" value={data.pa}/>                            
                            <InfoCard title="Talla:" value={data.talla}/>
                            <InfoCard title="Peso:" value={data.peso}/>
                            {data?.alergia && <InfoCard title="Alergia:" value={data.alergia}/>}
                            {data?.temperatura && <InfoCard title="Temperatura:" value={data.temperatura}/>}
                        </Grid>
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    )
}