import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import Diagnostico from "./Diagnostico";
import DiagnosticoForm from "../../core/Forms/DiagnosticoForm";
import Grid from "@material-ui/core/Grid";
import {connect} from 'react-redux';
import DialogFullScreen from "../../core/dialog/DialogFullScreen";
const useStyles = makeStyles((theme) => ({
    firstItem:{
        padding: theme.spacing(1),
        paddingTop:theme.spacing(10),
    },
    item: {
        padding: theme.spacing(1),
        paddingTop:theme.spacing(3),
    },
}));

function DiagnosticoView({paciente}) {
    const { id } = paciente;
    const classes = useStyles();
    return (
        <DialogFullScreen title={`Ficha médica N°${ id }`}>
            <Grid container>
                <Grid item lg={12} sm={12} xs={12} className={classes.firstItem}>
                    <Diagnostico />                    
                </Grid>

                <Grid item lg={12} sm={12} xs={12} className={classes.item}>
                    <DiagnosticoForm/>
                </Grid>
            </Grid>    
        </DialogFullScreen>  
    );
}

const mapStateToProps = (state) => ({     
    paciente: state.paciente.query
});

export default connect(mapStateToProps)(DiagnosticoView);