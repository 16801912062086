import { LOGIN_SUCCESS, LOGIN_FAIL, LOGOUT } from "../actions/types";

const user = JSON.parse(localStorage.getItem("user"));
//Si no existe en el localStorage el token, no estas registrado :D
const initialState = user
    ? { isLoggedIn: true, user }
    : { isLoggedIn: false, user: null };

export default function reducer(state = initialState, action) {
    const { type, payload } = action;

    switch (type) {        
        case LOGIN_SUCCESS:
            return {
                ...state,
                isLoggedIn: true,
                //Aqui se muestra el token
                user: payload.user,
            };
        case LOGIN_FAIL:
            return {
                ...state,
                isLoggedIn: false,
                user: null,
            };
        case LOGOUT:
            return {
                ...state,
                isLoggedIn: false,
                user: null,
            };
        default:
            return state;
    }
}