const defaultSizes = (props,{lg=12,sm=12,xs=12}) =>({
    lg: props.lg ?? lg,
    sm : props.sm ?? sm,
    xs : props.xs ?? xs,  
})

export default function getSizes(props){
    return defaultSizes(props,{sm:6})    
}
export const sizesCard = (props) =>(defaultSizes(props,{lg:3, sm:6, xs:6}))
export const sizesSelect = (props) =>(defaultSizes(props,{lg:5}))