import React from "react";
import { createMuiTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import {blue, indigo} from "@material-ui/core/colors";
import { connect } from "react-redux";
// -webkit-box-shadow: 0 0 0 100px red inset

const Palette = (props) => {
    const {nightMode} = props;
    const themeNight = createMuiTheme({
        palette: {
            type: "dark" ,
            primary: {
                light: blue[100],
                main: blue[300],
                dark: blue[500],
                contrastText:"#000",
            },
            secondary:{
                light: indigo[100],
                main: indigo[300],
                dark: indigo[500],
                contrastText:"#000",
            },
            background:{
                // on background
                default:"#121212",
                // On surface
                paper:"#303030"
            },
        },
        typography:{
            body1:{
                fontSize: '1.2rem',            
            },
            body2:{
                fontSize: '1rem',
            }
        }
    });
    
    const themeLight = createMuiTheme({
        palette: {
            type: "light",
            primary: {
                main: indigo[500],
            },
            secondary:{
                main: blue[500],
            },
        },
        typography:{
            body1:{
                fontSize: '1.2rem',            
            },
            body2:{
                fontSize: '1rem',
            }
        }
    });
    return <ThemeProvider theme={nightMode ? themeNight: themeLight}>{props.children}</ThemeProvider>
};

const mapStateToProps = (state) => ({
        nightMode: state.UI.nightMode,
    })

export default connect(mapStateToProps)(Palette);
