import React from "react";
import {useSelector } from "react-redux";
import {Card, CardActionArea, CardContent, Typography, Avatar} from "@material-ui/core"
export default function UserProfile() {
    const { user: currentUser } = useSelector(state => state.auth)
    
    return (
        <Card>
            <CardActionArea>
                <CardContent>                                        
                    <Avatar aria-label="user-profile">
                        {currentUser.user.username[0]}
                    </Avatar>
                    <Typography gutterBottom variant="h5" component="h2">
                        {currentUser.user.username}
                    </Typography>
                    <Typography
                        variant="body2"
                        color="textSecondary"
                        component="p"
                    >
                        {currentUser.rol}
                    </Typography>
                </CardContent>
            </CardActionArea>
        </Card>
    );
}
