import Imagen from "../../core/Imagen";
import Login from "./Login";
import Footer from "../../../layout/Footer/Footer";
import Grid from "@material-ui/core/Grid";
import Progress from "../../core/Progress";
import { connect } from "react-redux";
import { makeStyles } from '@material-ui/core/styles';
const useStyles = makeStyles(() => ({
    root: {
      height:"100vh",
    },
  }));
  
const LoginView = (props) => {
    const classes = useStyles();
    const { isLoad } = props
    return (
            <Grid container alignItems="center" className={classes.root}>
                <Grid item lg={12} xs={12}>
                    {isLoad && <Progress />}
                </Grid>
                <Grid item md={12} lg={6}>                    
                    <Login />                    
                </Grid>
                
                <Grid item lg={6}>
                    <Imagen />
                </Grid>
                <Footer />
            </Grid>                    
    );
};
//loading es el nombre en donde va el store
const mapStateToProps = (state) => ({ isLoad: state.UI.loading });
export default connect(mapStateToProps)(LoginView);
