import React,{ useEffect } from "react";
import Palette from "./layout/Palette";
import { Provider, useDispatch } from "react-redux";
import store from "./redux/store";
// import { clearMessage } from "./redux/actions/message";
import {clearUiMessage} from './redux/actions/ui'
import CssBaseline from "@material-ui/core/CssBaseline"
import { AppRouter } from "./routers/AppRouter";
import { history } from "./helpers/history";
const App = () => {
    const dispatch = useDispatch();  
    
    useEffect(() => {        
        history.listen((location) => {
            dispatch(clearUiMessage()); // clear message when changing location            
        });        
    }, [dispatch]);

    return (
        <Palette>
            <CssBaseline/>
            <AppRouter/>
        </Palette>
    );
};
function AppWrapper() {
    return (<Provider store={store}>
        <App/>
    </Provider>)
}
export default AppWrapper;
