import React from 'react'
// import { Container, makeStyles } from '@material-ui/core'
// import { DoctorRoomCard } from '../../core/Card/RoomCard/DoctorRoomCard'
// import { NurseRoomCard } from '../../core/Card/RoomCard/NurseRoomCard';
import TitleView from '../../core/TitleView'
import Examenes from "./Examen";

const ExamenView = () => {
    return (
        <>
            <TitleView title={`Examen`} />
            <Examenes />
        </>
    );
}

                                                                                                                                                                                                                                                                                                            export default ExamenView