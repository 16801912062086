import Configuracion from "./Configuracion";
import TitleView from "../../core/TitleView";
import Grid from "@material-ui/core/Grid";
import React from "react";
import {connect} from 'react-redux'
import { changeUiNightMode } from "../../../redux/actions/ui";

function ConfiguracionView(props) {
    const [state, setState] = React.useState(props.nightMode);

    const switchChange = ({target}) => {          
        setState(target.checked)          
        props.changeNightMode(target.checked)
      };
    return (
        <React.Fragment>
            <Grid container spacing={1}>
                <TitleView title="Configuración" />
                <Grid item lg={12} sm={12}>
                    <Configuracion checked={state} handleChange={switchChange} />
                </Grid>
            </Grid>
        </React.Fragment>
    );
}

/*
    La clave "nightMode" va como props en el componente a conectar
    el valor "state.nightMode" es aquel que va guardado en el nombre del reducer
    ubicado en el store dentro de combine reducer :D  
    
    Extrae el estado desde redux hacia el componente a conectar xdxd
*/

const mapStateToProps = state =>({ nightMode: state.UI.nightMode })

/*    
    La clave "changeNightMode" va como props en el componente a conectar
    tambien es el encargado de ejecutar la accion y posterior el reducer
    dispatch es el metodo que permite cambiar a un estado nuevo :D

    el atributo checked es aquel parametro por valor que sirve para modificar el estado
    desde el componente        
*/   
const mapDispatchToProps = dispatch =>({
    changeNightMode: checked => dispatch(changeUiNightMode(checked)) })

export default connect(mapStateToProps, mapDispatchToProps)(ConfiguracionView)