import { ListItem, ListItemIcon, ListItemText } from "@material-ui/core/"
export default function ItemWithinLink (props) {
    const {item,children} = props
    return (
        <ListItem button key={item.nombre} {...props}>
            <ListItemIcon key={item.id}>{item.icon}</ListItemIcon>
            <ListItemText key={item.nombre} primary={item.nombre} />
            {children}
        </ListItem>
    )
}