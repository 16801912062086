import React from 'react'

import FormControlLabel from '@material-ui/core/FormControlLabel';
import { CheckboxOnly } from './CheckboxOnly';

export const CheckboxField = (props) => {
    return (
        <FormControlLabel
        control={
          <CheckboxOnly {...props}/>
        }
        label={props.label}
      />
    )
}
