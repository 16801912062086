import React from "react";
import Drawer from "@material-ui/core/Drawer";
import { useTheme } from "@material-ui/core/styles";
export const GeneralDrawer = (props) =>{
    const {children, ...withoutChildren} = props
    return(
        <Drawer {...withoutChildren}>
            {children}
        </Drawer>
    )
}
export const ClinicalDrawer = props =>{
    const {children, ...withoutChildren} = props
    return(
        <GeneralDrawer
            variant={withoutChildren.variant ?? "permanent"}
            open={withoutChildren.mobileOpen ?? true}
            classes={{
                paper: withoutChildren.drawerPaper,
            }}
            {...withoutChildren}
        >
            {children}
        </GeneralDrawer>
    )

}
export const ClinicalMobileDrawer = (props) => {
    const theme = useTheme();
    return (
        <ClinicalDrawer
            container={props.container}
            variant="temporary"
            anchor={theme.direction === "rtl" ? "right" : "left"}
            open={props.mobileOpen}
            onClose={props.handleDrawerToggle}
            drawerPaper={props.drawerPaper}
            ModalProps={{
                keepMounted: true, // Better open performance on mobile.
            }}
        >
            {props.children}
        </ClinicalDrawer>
    );
};
