import ItemCollapsed from "./mini-components/ItemCollapsed";
import ItemNormal from "./mini-components/ItemNormal";
// import Badge from "@material-ui/core/Badge";
import React from "react";
// <Badge color="secondary" variant="dot" invisible={props.invisible}>
    //     {icon}
    // </Badge>)
    // if (props.item.sub && props.item.sub[1].route === "/app/datos_clinico")        
    //     props.item.sub[1].icon = iconModified(props.item.sub[1])

    // else if (props.item.route === "/app/datos_clinico")        
    //     props.item.icon = iconModified(props.item)                    
const TypeItem = (props) => {        

    return (props.item.sub ? (
        <ItemCollapsed item={props.item} />
    ) : (
        <ItemNormal to={props.item.route} item={props.item} />
    ))
}
export default TypeItem