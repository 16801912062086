import React from 'react'
import Checkbox from '@material-ui/core/Checkbox';

export const CheckboxOnly = (props) => {
    return (
        <Checkbox
            {...props}
          />
    )
}
