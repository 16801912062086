
import { React, useState } from "react";
import { connect } from "react-redux";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import { Button, DialogActions, DialogContent } from "@material-ui/core";
import Cargarbutton from "../core/Buttons/LaboratoryButton";
import { setCargarView } from "../../redux/actions/ui";
import { postExamPatient } from "../../services/paciente-service";
import { makeStyles } from "@material-ui/core";
const useStyles = makeStyles(() => ({
    content: {
        display: "flex",
        alignItems: "center"
    },
  }));

function SimpleDialogDemo({cargar, handleExamen, handleClose,
    disable, handleArchivos, archivos}) {
    const classes = useStyles();
    return (
        <Dialog 
            fullWidth
            maxWidth="md"
            onClose={handleClose}
            aria-labelledby="simple-dialog-title"
            open={cargar}
        >
            <DialogTitle id="simple-dialog-title">
                CARGAR EXAMEN AL SISTEMA
            </DialogTitle>
            <DialogContent className={classes.content}>
                <Cargarbutton handleArchivos={handleArchivos}/>
                <p>{archivos.name}</p>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="secondary" autoFocus>
                    Cerrar
                </Button>
                <Button variant="contained" disabled={!disable} onClick ={handleExamen} color="secondary" autoFocus>
                    Aceptar
                </Button>
            </DialogActions>
        </Dialog>
    );
}
function CargarArchivo(props) {
    const {closeCargar,  UI, rows} = props;
    const [archivosState, setArchivos] = useState({
        name: ""
    })
    const { cargarView } = UI;
    const [disable, setDisable] = useState(true);
    // console.log(rows);
    const handleClose = () => {
        closeCargar(false);
    };
    const subirArchivos = (e) => {
        const { files } = e.target;
        console.log(files[0])
        setArchivos(files[0]); 
    };

    const handleExamen = () =>
    {
        let formFile = new FormData();
        formFile.append("file", archivosState)
        formFile.append("idC", rows.id)
        postExamPatient(formFile)
            .then((response)=>{
                setDisable(false)
            })
            .catch((response)=> {
                alert("Verifique que ha escogido un archivo a enviar");
                console.log(response);
            });
    };
    
    return (        
        <SimpleDialogDemo
        handleArchivos={subirArchivos}
        archivos={archivosState}
        cargar={cargarView} 
        handleClose={handleClose} 
        handleExamen = {handleExamen}
        disable ={disable}/>            
    );
}

const mapStateToProps = (state) => ({
    UI: state.UI,
});

const mapDispatchToProps = (dispatch) => ({
    closeCargar: (open) => dispatch(setCargarView(open)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CargarArchivo);