import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Grid from "@material-ui/core/Grid";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import { getProperty,TYPE_FIELD } from '../../../helpers/utils'
import {sizesSelect} from '../../../helpers/sizes'
export default function SelectField(props){
    const sizes = sizesSelect(props)    
    const {label,name,form,handleChange,children} = props
    return(
        <Grid item lg={sizes.lg} sm={sizes.sm} xs={sizes.xs}>
            <FormControl variant={TYPE_FIELD} required={props.required ?? true} fullWidth>
                <InputLabel id={`id-${name}`}>{label}</InputLabel>
                <Select
                    labelId={`select-outlined-${name}`}

                    value={getProperty(form,name) }
                    onChange={handleChange}
                    label={label}
                    inputProps={{
                        name: name,
                    }}
                >
                    {children}
                    <MenuItem value="">
                        <em>NA</em>
                    </MenuItem>
                </Select>
            </FormControl>
        </Grid>
    )
}