import SettingsIcon from "@material-ui/icons/Settings";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import PermIdentityIcon from "@material-ui/icons/PermIdentity";
import StorageIcon from "@material-ui/icons/Storage";
import { cePath } from "../../helpers/utils";

let i = 0;
const menuEnfermero = [
    {
        id: i++,
        nombre: "Datos Personales",
        icon: <PermIdentityIcon />,
        route: `${cePath}/datos_personales`,
    },
    {
        id: i++,
        nombre: "Datos Clínico",
        icon: <StorageIcon />,
        route: `${cePath}/datos_clinico`,
    },

    {
        id: i++,
        nombre: "Configuración",
        icon: <SettingsIcon />,
        route: `${cePath}/configuracion`,
    },
    {
        id: i++,
        nombre: "Cerrar Sesión",
        icon: <ExitToAppIcon />,
        route: "/login",
    },
];
export default menuEnfermero;
