import React from 'react'
import PacienteSearch from '../../core/Field/PacienteSearch'
import TitleView from '../../core/TitleView'
import MedicalHistory from './MedicalHistory'

export const MedicalHistoryView = () => {
    return (
        <>
            <TitleView title="Historial Médico"/>
            <PacienteSearch/>
            <MedicalHistory/>
        </>
    )
}
