import { FormControlLabel } from "@material-ui/core";
import React from "react";
import Checkbox from '@material-ui/core/Checkbox';
export const CheckboxItem = ({checked = true, handleChange, name, label, color = "primary"}) => {
    return (
        <FormControlLabel
            control={
                <Checkbox
                    checked={checked}
                    onChange={handleChange}
                    name={name}
                    color={color}
                />
            }
            label={label}
        />
    );
};
