import React, { useState, useEffect } from "react";
import getDatosClinicos from "../../../services/signos-vitales-service";
import paciente from "../../../redux/actions/paciente";
import { connect } from "react-redux";
import DatoClinicoCard from './DatoClinicoCard'

function DatosClinico(props) {    
    const [data,setData] = useState({})    
    useEffect(() => {        
        if (props.getPaciente?.id) {
            getDatosClinicos(props.getPaciente.id)                
                .then((response) => {                    
                    setData(response.data)
                })
                .catch((error) => {
                    console.log(error);
                });
        }                
    }, [props]);  
    return <DatoClinicoCard data={data}/>            
}
const mapStateToProps = ({paciente}) => ({ getPaciente: paciente.query });
const mapDispatchToProps = (dispatch) => ({    
    changePacienteQuery: (query) => dispatch(paciente(query)),    
    
});

export default connect(mapStateToProps,mapDispatchToProps)(DatosClinico);
