import React from "react";
import Typography from "@material-ui/core/Typography";
import Hidden from "@material-ui/core/Hidden";
import withWidth from "@material-ui/core/withWidth";
import { makeStyles } from "@material-ui/core/styles";
const useStyles = makeStyles(() => ({
    item: {
        textAlign: "center",
    },
}));
function TitleView(props) {
    const classes = useStyles();
    return (
        <React.Fragment>
            <Hidden smUp>
                <Typography variant="h3" gutterBottom className={classes.item}>
                    {props.title}
                </Typography>
            </Hidden>
            <Hidden only={["xs", "lg", "xl"]}>
                <Typography variant="h2" gutterBottom className={classes.item}>
                    {props.title}
                </Typography>
            </Hidden>

            <Hidden only={["xs", "sm", "md"]}>
                <Typography variant="h1" gutterBottom className={classes.item}>
                    {props.title}
                </Typography>
            </Hidden>
        </React.Fragment>
    );
}
export default withWidth()(TitleView);
