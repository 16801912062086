import React, { useState } from 'react'
import { connect } from 'react-redux'
import { formErrorsPersonal } from '../../../helpers/utils'
import { setDialogTurn } from '../../../redux/actions/ui'
import { EditButton } from '../../core/Buttons/TableButtons'
import DialogFullScreen from '../../core/dialog/DialogFullScreen'
import DatosPersonalesForm from './DatosPersonalesForm'
import {putDatosPersonales} from '../../../services/datos-personales'
import { Grid } from '@material-ui/core'
import { makeStyles } from "@material-ui/core/styles";
import { memo } from 'react'
const useStyles = makeStyles((theme) => ({    
    item: {
        padding: theme.spacing(3),
        marginTop: theme.spacing(10)
    },
}));

const EditPatient = memo((props) => {
    const classes = useStyles();
    const { openDialogMajor, setDatosPersonales,
        dialogMajor,personalData, cedula} = props;
    const [formErrors,setFormErrors] = useState(formErrorsPersonal);
    const handleClick= ()=>{
        openDialogMajor(true);
    }
    const handleSubmit = (e) => {
        e.preventDefault();
        setDatosPersonales(personalData,cedula)
        .catch(()=> console.log("Error"));
    };
    return (
        <>
            <EditButton
                onClick={handleClick}
                fullWidth
            />
            {dialogMajor && 
                <DialogFullScreen title="Editar Paciente">
                    <Grid container>
                        <Grid item lg={12} sm={12} xs={12} className={classes.item}>    
                            <DatosPersonalesForm
                                handleSubmit={handleSubmit}
                                formErrors={formErrors} setFormErrors={setFormErrors}
                            />
                        </Grid>
                    </Grid>
                </DialogFullScreen>
            }
        </>
    )
})
const mapStateToProps = state =>({
    dialogMajor: state.UI.dialogMajor,
    personalData: state.paciente.personalData,
    cedula: state.paciente.query.cedula,
})
const mapDispatchToProps = (dispatch) => ({    
    openDialogMajor: (check) => dispatch(setDialogTurn(check)),
    setDatosPersonales:(form,cedula) => dispatch(putDatosPersonales(form,cedula)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditPatient);