import React from 'react';
import { Alert, AlertTitle } from '@material-ui/lab';

//error, warning, info, success
export default function DescriptionAlerts(props) {
  const { title } = props;
  return (
      <Alert severity={props.type ?? "info"}>
        {title && <AlertTitle><b>{title}</b></AlertTitle>}
        {props.description}
      </Alert>      
  );
}
