import { paciente } from "./types";
import getMedicinas  from "../../services/medicamentos-service";
import getCies  from "../../services/cie-service";
import { setUiMessage, setDialog, uiLoading } from './ui'
import { postExamPatient } from "../../services/auth.service";
import { factoryError } from "./auth";

export const getMedicines = () => dispatch =>{
    return new Promise((resolve,reject) =>{
        getMedicinas().then(response =>{
            resolve(response)
        })
        .catch(err =>{
            const message = err?.response?.data?.errors || err.toString() || "Error inesperado";
            dispatch(setUiMessage(message));
            dispatch(setDialog(true));
            reject();
        })
    })
}

export const getCias = () => dispatch =>{
    return new Promise((resolve,reject) =>{
        getCies().then(response =>{
            resolve(response)
        })
        .catch(err =>{
            const message = err?.response?.data?.errors || err.toString() || "Error inesperado";
            dispatch(setUiMessage(message));
            dispatch(setDialog(true));
            reject();
        })
    })
} 

export const loginPatientAuth = (form) => (dispatch) => {
    dispatch(uiLoading(true));
    return new Promise((resolve,reject)=>{
        postExamPatient(form).then((data) => {
            //MOdifico el estado a LOGIN_SUCCESS y su payload a la response
            dispatch(setExamsCredentials({
                cedula: data.cedula,
                examen_laboratorio: data.examen_laboratorio,
            }))
            resolve(data);  
        })
        .catch((error) => {
            // Guardo el msj de error
            const message = factoryError(error)
            // dispatch(loginFail());
            // Seteo el msj para anexarle el error
            dispatch(setUiMessage(message));
            dispatch(setDialog(true));
            
            // reject(error?.response?.data);
            reject(message);
        })
        .finally(()=>dispatch(uiLoading(false)));
    })
};


const setPaciente = query =>({
    type: paciente.setPaciente,
    payload: { query },  
})
export const setPatientPersonalData = personalData =>({
    type: paciente.setDataPersonalPaciente,
    payload: { personalData }
})
export const clearPaciente = () =>({
    type:paciente.clearPaciente,
})
export const clearPersonalData = ()=>({
    type: paciente.clearPersonalData,
})


export const setExamsCredentials = examsCredentials =>({
    type: paciente.loginPatientSuccess,
    payload: { examsCredentials },
})


export default setPaciente