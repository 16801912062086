import React from 'react'
import { connect } from 'react-redux';
import { setDialogTurn } from '../../../redux/actions/ui';
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import { IconButton, Toolbar, Typography } from '@material-ui/core';
import CloseIcon from "@material-ui/icons/Close";
import { makeStyles } from "@material-ui/core/styles";
import { GeneralApp } from '../navbar/GeneralAppBar';
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} keepMounted/>;
});
const useStyles = makeStyles((theme) => ({    
    item: {
        padding: theme.spacing(1),
    },

    appBar: {
        position: "relative",
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
}));

const DialogFullScreen = ({UI, close, children, title}) => {
    const classes = useStyles();
    const {dialogMajor} = UI;
    const handleClose = () =>{
        close(false)
    }
    return (
        <Dialog
            fullScreen
            maxWidth="lg"
            open={dialogMajor}
            onClose={handleClose}
            TransitionComponent={Transition}
        >
            <GeneralApp position="fixed">
                <Toolbar>
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={handleClose}
                        aria-label="close"
                    >
                        <CloseIcon />
                    </IconButton>
                    <Typography variant="h5" className={classes.title}>
                        {title}
                    </Typography>                            
                </Toolbar>
            </GeneralApp>
            {children}
        </Dialog>
    )
}
const mapStateToProps = (state) => ({     
    UI: state.UI,
    paciente: state.paciente.query
});
const mapDispatchToProps = (dispatch) => ({    
    close: (check) => dispatch(setDialogTurn(check))    
});

export default connect(mapStateToProps,mapDispatchToProps)(DialogFullScreen);
