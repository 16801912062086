import List from "@material-ui/core/List";
import TypeItem from './TypeItem'
import React from "react";
export default function MenuItems(props) {
    // const [invisible,setInvisible] = React.useState(props.notify.clinicalData);
    // React.useEffect(()=>{        
    //     setInvisible(props.notify.clinicalData)
    // },[props])    
    // console.log({...props.iconshospital})
    return (
        <List {...props}>
            {props.iconshospital.map((a) => {
                return <TypeItem key={`${a.id}tipo_item`} item={a}/>
            })}
        </List>
    );
}