import React, { useEffect, useState } from 'react'
import Table from "@material-ui/core/Table";
import TableContainer from "@material-ui/core/TableContainer";
import Paper from "@material-ui/core/Paper";
// import DiagnosticoView from "../MedicalRecord/DiagnosticoView";
import {getExamsPacients} from "../../../services/paciente-service";
import { connect } from "react-redux";
// import paciente from "../../../redux/actions/paciente";
// import { setDialogTurn } from "../../../redux/actions/ui";
import { TableFoot } from "../../core/Table/TableFoot";
import { TableContent } from "../../core/Table/TableContent";
import { Laboratorybutton} from "../../core/Buttons/LaboratoryButton";
import { TableHeader } from "../../core/Table/TableHeader";
import { usePaginator } from "../../../hooks/usePaginator";
// import { makeStyles } from "@material-ui/core/styles";
import { getHeaders } from "../../../helpers/utils";
import { DeleteButton} from "../../core/Buttons/TableButtons";
import CargarArchivo from "../../core/CargarArchivo";
import { setCargarView } from '../../../redux/actions/ui';
// id, examen
const headers = getHeaders([
    "ID Examen",
    "Cédula",
    "Paciente",
    "Fecha",
    "Subir",
    "Eliminar"
])

const ExamLaboratory = (props)=>{                                                                                                                                                                                                                                                                                                                                                                 
    const { page, rowsPerPage, handleChangePage, handleChangeRowsPerPage } = usePaginator();
    const { cargarView } = props.UI;
    const [id, setId] = useState([])
    const [examen, setExamen] = useState([])

    const handleClick = (row,index=0) => {
        props.opened(true);
        setId(row)
    };
    

    useEffect(() => {
        getExamsPacients()
            .then(response =>{
                setExamen(response)
            })
            .catch((error) => console.log(error))
    },[])
    return (
        <>
            <TableContainer component={Paper}>
                <Table aria-label="Tabla de Laboratorio">
                    <TableHeader headers={headers}/>
                    <TableContent rows={examen}                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                 
                        rowsPerPage={rowsPerPage} 
                        page={page}
                        components={[
                            [{handleE: handleClick, Component: Laboratorybutton}],
                            [{handleE: ()=>{}, Component: DeleteButton}]
                        ]}
                    />
                    <TableFoot count={examen.length} 
                        page={page} handleChangePage={handleChangePage}
                        rows={rowsPerPage} handleChangeRowsPerPage={handleChangeRowsPerPage}
                    />
                </Table>
            </TableContainer>
            {cargarView &&  
                <CargarArchivo rows={id}/>
            }
        </>
    );

}


const mapStateToProps = (state) => ({
    UI: state.UI,
});
const mapDispatchToProps = (dispatch) => ({
    opened: (check) => dispatch(setCargarView(check)),
});


export default connect(mapStateToProps,mapDispatchToProps)(ExamLaboratory);


