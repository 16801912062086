import axios from "axios";
import API_URL from "./api-clinica-url";
import authHeader from "./auth-header";

export const postMedicinas = (form) =>
    new Promise((resolves, rejects) => {
        axios
            .post(`${API_URL}/api/medicamentos/`, form, {
                headers: authHeader(),
            })
            .then((response) => resolves(response.data))
            .catch((err) => rejects(err));
    });

const getMedicinas = () =>
    new Promise((resolve, reject) => {
        axios
            .get(`${API_URL}/api/medicamentos/`, { headers: authHeader() })
            .then((response) => resolve(response.data))
            .catch((response) => reject(response)) 
    });

export default getMedicinas;
