import React from 'react'
import { useParams } from 'react-router'

export const KardexView = () => {
    const { room } = useParams();
    return (
        <div>
            Kardex de la Habitacion {room}
        </div>
    )
}
