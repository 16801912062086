import { CardActionArea, CardContent, CardMedia, makeStyles, Typography } from '@material-ui/core'
import React from 'react'
import { useHistory } from 'react-router';
import { enfermeriaPath } from '../../../../helpers/utils';
import { RoomCard } from './RoomCard'

const useStyles = makeStyles((theme) => ({
    media: {
        height: 150,
    },
    
}));

export const DoctorRoomCard = ({id}) => {
    const classes = useStyles();
    const history = useHistory();
    const clickRegistroAdmision=()=>{
        history.push(`${enfermeriaPath}/${id}/registro`)
    }
    return (
        <RoomCard id={id}
        >
            <CardActionArea onClick={clickRegistroAdmision}>
                <CardContent>
                <Typography gutterBottom variant="body2">
                    Paciente: Alex Cuenca M
                </Typography>
                <CardMedia
                    className={classes.media}
                    image="/assets/Group-15-dark.png"
                    title="Registro de Admisión"
                    />
                </CardContent>
            </CardActionArea>
        </RoomCard>
    )
}
