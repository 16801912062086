import { connect } from "react-redux";
import paciente from "../../redux/actions/paciente";
import Grid from "@material-ui/core/Grid";
import React from "react";

import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import DatosClinicoAnterior from "./Card/DatosClinicoAnterior";
import DatosPaciente from "./Card/DatosPaciente";//Cambiar DatosPaciente
import TableData from "./Table/TableData";
import { setDialogView } from "../../redux/actions/ui";
import { Button, DialogActions, DialogContent } from "@material-ui/core";
import { getHeaders } from "../../helpers/utils";

function SimpleDialogDemo({handleClose, id, dialog, children}) {
    return (
        <Dialog 
            fullWidth
            maxWidth="md"
            onClose={handleClose}
            aria-labelledby="simple-dialog-title"
            open={dialog}
        >
            <DialogTitle id="simple-dialog-title">
                Ficha Clínica {id}
            </DialogTitle>
            <DialogContent>
                {children}
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="secondary" autoFocus>
                    Cerrar
                </Button>
            </DialogActions>
        </Dialog>
    );
}

const headerMedicinas = getHeaders([
    "Medicamento",
    "Cantidad",
    "Preescripcion"
]);
function UltimaFichaMedica(props) {
    const {closeDialog, UI, fichaMedica} = props
    console.log(props)
    const { dialogView } = UI;
    const handleClose = () => {
        closeDialog(false);
    };

    return (
        <SimpleDialogDemo
            id={fichaMedica.id}
            handleClose={handleClose}
            dialog={dialogView}
        >
            <Grid container>
                <Grid item lg={12} sm={12} xs={12}>
                    <Grid container>
                        <Grid item lg={12} sm={12} xs={6}>
                            <p>Fecha de ingreso: {fichaMedica.fechaIngreso}</p>
                        </Grid>
                        <Grid item lg={12} sm={12} xs={6}>
                            <p>Medico: {fichaMedica.medico}</p>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item lg={12} sm={12} xs={12}>
                    <DatosPaciente />
                </Grid>

                <Grid item lg={12} sm={12} xs={12}>
                    <DatosClinicoAnterior id={fichaMedica.id} />
                </Grid>
                <Grid item lg={12} sm={12} xs={12}>
                    <Grid container justify="center">
                        <Grid item lg={12} sm={12} xs={12}>
                            <p>Evolución: {fichaMedica.evolution ?? "NA"}</p>
                        </Grid>
                        <Grid item lg={6} sm={6} xs={6}>
                            <p>Motivo de la consulta: {fichaMedica.motivo}</p>
                        </Grid>
                        <Grid item lg={6} sm={6} xs={6}>
                            <p>Diagnostico: {fichaMedica.diagnostico ?? "NA"}</p>
                        </Grid>
                    </Grid>
                </Grid>


                <Grid item lg={12} sm={12} xs={12}>
                    <TableData
                        headers={headerMedicinas}
                        rows={fichaMedica.tratamiento}
                        fieldsHidden={["id"]}
                    />
                </Grid>
            </Grid>
        </SimpleDialogDemo>
    );
}

const mapStateToProps = (state) => ({
    paciente: state.paciente.query,
    UI: state.UI,
    fichaMedica: state.fichaMedica,
});
const mapDispatchToProps = (dispatch) => ({
    changePacienteQuery: (query) => dispatch(paciente(query)),
    closeDialog: (open) => dispatch(setDialogView(open)),
});

export default connect(mapStateToProps, mapDispatchToProps)(UltimaFichaMedica);
