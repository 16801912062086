import { useState, useEffect } from "react";

import Grid from "@material-ui/core/Grid";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";

import getPacientes from "../../../services/paciente-service";
import { connect } from "react-redux";
import paciente from "../../../redux/actions/paciente";
import React from "react";
import {TYPE_FIELD } from '../../../helpers/utils'
function PacienteSearch(props) {
    const [pacientes, setPacientes] = useState([]);
    const [value, setValue] = useState(null);
    
    useEffect(() => {
        setValue(props.paciente)
        getPacientes()
            .then((response) => {    
                setPacientes(response);
            })
            .catch((error) => {
                console.log(error);
            });
    }, [props]);
    const handleChange = (newOption) => {
        setValue(newOption);

        props.changePacienteQuery(newOption ?? {
            ...newOption,
            alergia:"",
            alergias:""            
        });

    };
    return (
        <React.Fragment>
            <Grid item lg={12} sm={12} xs={12}>
                <Autocomplete
                    value={value}
                    options={pacientes}
                    getOptionLabel={(options) => options.nombre}
                    onChange={(e, newOption) => handleChange(newOption)}
                    getOptionSelected={(option,value) => option.cedula === value.cedula}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label="Nombre del paciente"
                            variant={TYPE_FIELD}
                        />
                    )}
                />
            </Grid>
        </React.Fragment>
    );
}

const mapStateToProps = (state) => ({ paciente: state.paciente.query });
const mapDispatchToProps = (dispatch) => ({
    changePacienteQuery: (query) => dispatch(paciente(query)),
});
export default connect(mapStateToProps, mapDispatchToProps)(PacienteSearch);
