import axios from "axios";
import API_URL from "./api-clinica-url";
import authHeader from "./auth-header";

export const getFichasMedicas = (id) =>
    new Promise((resolves, rejects) => {
        axios
            .get(`${API_URL}/ficha_medica/pdf/${id}/`, { headers: authHeader(),
                responseType: 'blob'
            })
            .then((response) => resolves(response.data))
            .catch((error) => rejects(error.response)) 
    });
export const postFichasMedicas = (form) =>
    new Promise((resolves, rejects) => {
        axios
            .post(`${API_URL}/api/turnos/`,form, { headers: authHeader()})
            .then((response) => resolves(response.data))
            .catch((response) => rejects(response)) 
    });

export default getFichasMedicas;
