import React from "react";
import {AppNavBar} from './AppNavBar'
import Hidden from "@material-ui/core/Hidden";
import tipoMenu from '../../../layout/Menu/tipoMenu'
import UserProfile from "../../core/UserProfile";
import { useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import { myStyles } from "./styles";
import MenuItems from "../../../layout/Menu/MenuItems";
import { ClinicalDrawer, ClinicalMobileDrawer } from "./ClinicalDrawer";
function NavBar(props) {
    const { user: currentUser } = useSelector((state) => state.auth);
    const { window } = props;
    const classes = myStyles();
    const [mobileOpen, setMobileOpen] = React.useState(false);
    if (!currentUser) {
        return <Redirect to="/login" />;
    }
    const typeItem = tipoMenu(currentUser.rol)
    
    const handleDrawerToggle = (e) => {                
        setMobileOpen(!mobileOpen);
    };    

    const container =
        window !== undefined ? () => window().document.body : undefined;

    return (
        <div className={classes.root}>
            <AppNavBar classes={classes}
                handleDrawerToggle={handleDrawerToggle}
                name="Clinica Gilces"
            />

            <nav className={classes.drawer} aria-label="mailbox folders">
                {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
                <Hidden smUp implementation="css">
                    <ClinicalMobileDrawer
                        container={container}
                        mobileOpen={mobileOpen}
                        handleDrawerToggle={handleDrawerToggle}
                        drawerPaper={classes.drawerPaper}
                    >
                        <UserProfile />
                        <MenuItems iconshospital={typeItem} />
                    </ClinicalMobileDrawer>
                </Hidden>
                <Hidden xsDown implementation="css">
                    <ClinicalDrawer drawerPaper={classes.drawerPaper}>       
                        <UserProfile />
                        <MenuItems iconshospital={typeItem} />                 
                    </ClinicalDrawer>
                </Hidden>
            </nav>
            <main className={classes.content}>
                <div className={classes.toolbar}/>
                {props.children}
            </main>
        </div>
    );
}

export default NavBar;
