import { React, useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import MenuItem from "@material-ui/core/MenuItem";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, connect } from "react-redux";
import notifies from "../../../redux/actions/notifies";
import getMedicos from "../../../services/medico-service";
import AlertDialog from "../../core/AlertDialog";
import SelectField from "../../core/Field/SelectField";
import ClinicalTextField from "../../core/Field/TextField";
import { postDatosClinico } from "../../../services/datos-clinico";
import { InputAdornment } from "@material-ui/core";
import { RadioField } from "../../core/Field/radio/RadioField";
import { RadioItem } from "../../core/Field/radio/RadioItem";
const useStyles = makeStyles(() => ({
    labelRoot:{
        width:75,
    },
    item: {
        textAlign: "center",
    },
}));
const formDefault = {
    frecuenciaCardiaca: "",
    saturacionOxigeno: "",
    presionDiastolica: "",
    presionSistolica:"",
    talla: "",
    peso: "",
    temperatura: "",
    medico: "",
    especialidad: "",
    alergia: "",
}
const validateForm = (form) =>{
    const { frecuenciaCardiaca, saturacionOxigeno,
        presionDiastolica, presionSistolica, talla, peso, temperatura
         ,...withoutClinicalData} =  form;

    const signovital={
        frecuenciaCardiaca, saturacionOxigeno,
        presionDiastolica, presionSistolica, talla, peso, temperatura
    }
    return {...withoutClinicalData, signovital}
} 

function DatosClinicoForm({UI, paciente}) {
    const dispatch = useDispatch();
    const classes = useStyles();
    const [alergic, setAlergic] = useState(paciente.alergia);
    const [title, setTitle] = useState("");
    const {loading} = UI;
    const [form, setForm] = useState(formDefault);
    
    const [medicos, setMedicos] = useState([]);
    const [especialidades, setEspecialidades] = useState([]);
    useEffect(() => {
        const alergias = paciente?.alergias ?? "";
        setForm((formy) => ({
            ...formy,
            alergia: alergias,
        }));
    }, [paciente]);
    useEffect(() => {
        setAlergic(paciente.alergia);
    }, [paciente]);
    useEffect(() => {
        dispatch(getMedicos()) 
            .then((response) => {                
                setMedicos(response);
            })
    }, [dispatch]);
    const handleChangeEspecialidad = (e) => {
        handleChange(e);
        medicos.forEach((medico) => {
            if (!e.target?.value) setEspecialidades([]);
            else if (medico.cedula === e.target.value) {
                setEspecialidades(medico.especialidades);
            }
        });
    };
    const handleChange = ({ target }) => {
        const { name, value } = target;
        setForm({
            ...form,
            [name]: value,
        });
    };
    const handleClick = () => {
        dispatch(
            notifies({
                clinicalData: false,
            })
        );
        setForm({
            ...form,
            paciente: paciente.cedula,
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();        
        const postForm = validateForm(form);
        dispatch(postDatosClinico(postForm))
            .then(() => {
                setTitle("Registro exitoso");
                setForm(formDefault);
            })
            .catch(() => { 
                setTitle("Registro fallido");
            });
    };
  
    const handleChangeAlergia = ({ target }) => setAlergic(target.value);
    return (
        <form onSubmit={handleSubmit}>
            <Grid container alignItems="center" justify="center" spacing={3}>
                <AlertDialog
                    title={title}
                />
                <ClinicalTextField
                    lg={2}
                    name="frecuenciaCardiaca"
                    form={form}
                    label="F.C."
                    type="number"
                    handleChange={handleChange}
                />

                <ClinicalTextField
                    lg={2}
                    name="saturacionOxigeno"
                    form={form}
                    label="SPO"
                    type="number"
                    handleChange={handleChange}
                />

                <ClinicalTextField
                    lg={2}
                    name="presionSistolica"
                    form={form}
                    label="P.A.S"
                    type="number"
                    handleChange={handleChange}
                />
                <ClinicalTextField
                    lg={2}
                    name="presionDiastolica"
                    form={form}
                    label="P.A.D"
                    type="number"
                    handleChange={handleChange}
                />
                <ClinicalTextField
                    lg={2}
                    sm={6}
                    name="talla"
                    form={form}
                    label="Talla"
                    type="number"
                    handleChange={handleChange}
                    InputProps={{
                        endAdornment: <InputAdornment position="end">m</InputAdornment>,
                    }}
                    required={false}
                />
                <ClinicalTextField
                    lg={2}
                    sm={6}
                    name="peso"
                    label="Peso"
                    form={form}
                    handleChange={handleChange}
                    type="number"
                    InputProps={{
                        endAdornment: <InputAdornment position="end">Kg</InputAdornment>,
                    }}
                    required={false}
                />
                
                <ClinicalTextField
                    lg={3}
                    sm={6}
                    name="temperatura"
                    label="Temperatura"
                    form={form}
                    handleChange={handleChange}
                    type="number"
                    InputProps={{
                        endAdornment: <InputAdornment position="end">C°</InputAdornment>,
                    }}
                    required={false}
                />

                <SelectField
                    lg={3}
                    label="Doctor"
                    form={form}
                    name="medico"
                    handleChange={handleChangeEspecialidad}
                >
                    {medicos &&
                        medicos.map((medico) => (
                            <MenuItem value={medico.cedula}>
                                {medico.nombre}
                            </MenuItem>
                        ))}
                </SelectField>
                
                <SelectField
                    lg={3}
                    label="Especialidad"
                    form={form}
                    name="especialidad"
                    handleChange={handleChange}
                >
                    {especialidades &&
                        especialidades.map((medico) => (
                            <MenuItem value={medico.especialidades}>
                                {medico.especialidades}
                            </MenuItem>
                        ))}
                </SelectField>
                <Grid item xs={12} sm={12} lg={3}>
                    <RadioField title="Alergia"
                        handleChange={handleChangeAlergia}
                        name="alergic"
                        value={alergic}
                        row
                    >
                        <RadioItem value="Si" label="Si" classes={classes}/>
                        <RadioItem value="No" label="No" classes={classes}/>
                    </RadioField>
                </Grid>

                {alergic === "Si" && (
                    <ClinicalTextField
                        sm={12}
                        name="alergia"
                        label="Descripción"
                        form={form}
                        multiline
                        handleChange={handleChange}
                    />
                )}

                <Grid item lg={12} sm={12} xs={12} className={classes.item}>
                    <Button
                        variant="contained"
                        color="primary"
                        type="submit"
                        onClick={handleClick}
                        disabled={loading}
                    >
                        Registrar
                    </Button>
                </Grid>
            </Grid>
        </form>
    );
}
const mapStateToProps = (state) => ({ 
    paciente: state.paciente.query,
    UI: state.UI,
});
export default connect(mapStateToProps)(DatosClinicoForm);
