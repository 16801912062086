import React from "react";
import DatosPaciente from "../../core/Card/DatosPaciente";
import DatosClinico from "../../core/Card/DatosClinico";//Esto es una card
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
const useStyles = makeStyles((theme) => ({    
    item: {                
        paddingBottom: theme.spacing(3)
    },
}));

export default function Diagnostico(props) {
    
    const classes = useStyles()
    return (        
        <Grid container className={classes.item} >            
            <Grid item lg={12} sm={12} xs={12} className={classes.item}>
                <DatosPaciente />
            </Grid>
            <Grid item lg={12} sm={12} xs={12}>
                <DatosClinico/>
            </Grid>
            
        </Grid>        
    );
}
