import Button  from "@material-ui/core/Button";
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import { makeStyles } from "@material-ui/core";
const useStyles = makeStyles((theme) => ({
    root: {
      '& > *': {
        margin: theme.spacing(1),
      },
    },
    input: {
      display: 'none',
    },
  }));

export const Laboratorybutton = (props) => {   
    return (
    <Button
        variant="contained"
        color="primary"
        {...props}
    >
        <CloudUploadIcon />
    </Button>
    
)};


const Cargarbutton = ({handleArchivos}) => {
    const classes = useStyles();
    
    return (
        <div className={classes.root}>
            <input type="file" className={classes.input} name="files" onChange={handleArchivos} id="button-file"/>
            <label htmlFor="button-file">
                <Button variant="contained" 
                color="primary"
                component="span"
                >
                    Upload
                </Button>
            </label>
        </div>

    )
}

export default Cargarbutton;