import axios from "axios";
import API_URL from "./api-clinica-url"

//Retorno la data y guardo el token en el localstorage llamado user
const login = async onlyCrypted => await axios.post(`${API_URL}/login`, onlyCrypted)
    .then(response => {
        if (response.data.access_token){
            localStorage.setItem("user", JSON.stringify(response.data));            
        }

        return response.data
    })  

export const postExamPatient = (form) =>
    new Promise((resolves, rejects) => {
        axios
            .post(`${API_URL}/paciente/login`,form)
            .then((response) => {
                resolves(response?.data)
                localStorage.setItem("patient-user",JSON.stringify(response.data))
            })
            .catch((response) => rejects(response)) 
    });
    // https://api-clinica-dev.herokuapp.com/paciente/login
export const logout = () => {
    localStorage.removeItem("user");
    localStorage.removeItem("patient-user");
}    

export default login