import React, { useEffect } from "react";
import Table from "@material-ui/core/Table";
import TableContainer from "@material-ui/core/TableContainer";
import Paper from "@material-ui/core/Paper";
import DiagnosticoView from "../MedicalRecord/DiagnosticoView";
import getTurnos,{deleteTurnos} from "../../../services/turno-service";
import { connect } from "react-redux";
import paciente from "../../../redux/actions/paciente";
import { setDialogTurn } from "../../../redux/actions/ui";
import { TableFoot } from "../../core/Table/TableFoot";
import { TableContent } from "../../core/Table/TableContent";
import { DeleteButton, DiagnosticButton } from "../../core/Buttons/TableButtons";
import { TableHeader } from "../../core/Table/TableHeader";
import { usePaginator } from "../../../hooks/usePaginator";
import { makeStyles } from "@material-ui/core/styles";
import { getHeaders } from "../../../helpers/utils";
const useStyles = makeStyles((theme) => ({
    table: {
        minWidth: 480,
    },
}));

const headers = getHeaders([
    "ID Ficha Medica",
    "Cédula",
    "Paciente",
    "Enfermero/a",
    "Diagnosticar",
    "Eliminar"
])

const newPaciente = (pacientes) =>{ 
    const newPatients = [...pacientes] 
    return newPatients.map(paciente => {
        const {cedula_enfermero, ...newPaciente} = paciente;
        return newPaciente;
    })
}

const sortFichas = ({ data }) => {
    data.sort((a, b) => a.id - b.id);
    return data
};

function TablaFichaMedica(props) {
    const classes = useStyles();
    const { dialogMajor } = props.UI;    
    const [pacientes, setPacientes] = React.useState([]);
    const { page, rowsPerPage, handleChangePage, handleChangeRowsPerPage } = usePaginator();

    useEffect(() => {
        getTurnos()
            .then((response) => {
                setPacientes(sortFichas(response));
                
            })
            .catch((error) => {
                setPacientes([])
                alert(error.errors)
            });
    }, [dialogMajor]);    

    const handleClick = (row,index=0) => {
        props.changePacienteQuery({
            cedula: row.cedula,
            nombre: row.nombres,
            id: row.id,
            alergia: "NA",
            ultimoId: row.anterior,
        });
        props.opened(true);
    };
    const deletePacienteTurn = (index) =>{
        const pacienteCopy = [...pacientes] //pacientes.splice(index,1)  
        pacienteCopy.splice(index - 1,1) 
        console.log(pacienteCopy)
        return pacienteCopy;
    }    
    const deleteRow = (row,index) =>{
        const pacienteCopy = deletePacienteTurn(index)
        
        deleteTurnos(row.id)
            .then(({data}) =>{                
                alert(data.msj)
                setPacientes(pacienteCopy)
            } )
            .catch(error =>alert(error))
    }
    return (
        <>
            <TableContainer component={Paper}>
                <Table aria-label="Tabla de fichas médica" className={classes.table}>
                    <TableHeader headers={headers}/>
                    <TableContent rows={newPaciente(pacientes)} 
                        rowsPerPage={rowsPerPage} 
                        page={page}
                        fieldsHidden={["anterior"]}
                        components={[
                            [{handleE: handleClick, Component: DiagnosticButton}],
                            [{handleE: deleteRow, Component: DeleteButton}]
                        ]}
                    />

                    <TableFoot count={pacientes.length} 
                        page={page} handleChangePage={handleChangePage}
                        rows={rowsPerPage} handleChangeRowsPerPage={handleChangeRowsPerPage}
                    />
                </Table>
            </TableContainer>
            {dialogMajor && 
                <DiagnosticoView />
            }
        </>
    );
}
const mapStateToProps = (state) => ({
    paciente: state.paciente.query,
    UI: state.UI,
});
const mapDispatchToProps = (dispatch) => ({
    changePacienteQuery: (query) => dispatch(paciente(query)),
    opened: (check) => dispatch(setDialogTurn(check)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TablaFichaMedica);