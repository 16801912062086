import React from "react";
import TextField from "@material-ui/core/TextField";
import Autocomplete, {
    createFilterOptions,
} from "@material-ui/lab/Autocomplete";
import { getProperty, TYPE_FIELD } from "../../../../helpers/utils";

const filter = createFilterOptions();

const filterCies = (options, params) => {
    const filtered = filter(options, params);

    if (params.inputValue !== "") {
        filtered.push({
            inputValue: params.inputValue
        });
    }

    return filtered;
};

const labelOptions = (option) => {
     if (typeof option === "string") return option;
    if (option.inputValue) return option.inputValue;
    return option.cie;
};
export default function CieAutocomplete(props) {
    const [open, toggleOpen] = React.useState(false);
    const { form, name, handleChange } = props;
    const [value, setValue] = React.useState(getProperty(form, name));
    const [cieValue, setCieValue] = React.useState({
        cie: "",
    });

    const eventArfititial = (newValue) => ({
        target: {   
            name,
            value: newValue?.cie ?? "",
        },
    });

    const handleChangeCie = (newValue) => {
        if (typeof newValue === "string") {
            // timeout to avoid instant validation of the dialog's form.
            setTimeout(() => {
                toggleOpen(true);
                setCieValue({
                    cie: newValue,
                });
            });
        } else if (newValue?.inputValue) {
            toggleOpen(true);
            setCieValue({
                cie: newValue.inputValue,
            });
        } else setValue(newValue);
    };
    return(
        <React.Fragment>
            <Autocomplete
                fullWidth={true}
                value={value}
                onChange={(e, newValue) => {
                    const event = eventArfititial(newValue);
                    handleChange(event, form.row);
                    handleChangeCie(newValue);
                }}
                filterOptions={(options, params) =>
                    filterCies(options, params)
                }
                options={props.cies}
                getOptionLabel={(option) => labelOptions(option)}
                selectOnFocus
                clearOnBlur
                handleHomeEndKeys
                renderOption={(option) => option.cie}
                freeSolo
                renderInput={(params) => (
                    <TextField {...params} label="Diagnóstico CIE10" variant={TYPE_FIELD} />
                )}
            /> 
        </React.Fragment>
    )
}
