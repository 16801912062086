import React from "react";
import Grid from "@material-ui/core/Grid";
import TitleView from "../../core/TitleView";
import DatosPaciente from "../../core/Card/DatosPaciente";
import DatosClinicosForm from "./DatosClinicoForm";
import PacienteSearch from "../../core/Field/PacienteSearch";
import Progress from "../../core/Progress";
import { connect } from "react-redux";
import EditPatient from "../PersonalData/EditPatient";

const DatosClinicosView = (props) => {
    const { isLoad } = props
    return (
        <Grid container justify="center" spacing={3}>
            <TitleView title="Datos Clínicos" />
            <PacienteSearch />
            <Grid item lg={12} sm={12}>
                <DatosPaciente>
                    <EditPatient/>    
                </DatosPaciente>
            </Grid>
            {/* Iria un prop.cedula o algo que identifique al paciente */}
            <Grid item lg={12} sm={12}>
                <DatosClinicosForm />
            </Grid>
            {isLoad && <Progress/>}
        </Grid>
    );
};
const mapStateToProps = (state) => ({ isLoad: state.UI.loading });
export default connect(mapStateToProps)(DatosClinicosView);
