import { React } from "react";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import { Redirect } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import AlertDialog from "../AlertDialog";
import PasswordField from '../Field/PasswordField'
import ClinicalTextField from '../Field/TextField'
import { useSelector } from "react-redux";
import { InputAdornment } from "@material-ui/core";
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import { cePath } from "../../../helpers/utils";

const useStyles = makeStyles(() => ({   
    root:{        
        padding:"3em"
    }, 
    item: {
        textAlign: "center",        
        paddingTop:"2em",
    },
}));
const getPath = (user) =>{
    if(user?.rol ==="Laboratorio")
        return cePath
    else return "/app"

}
export default function LoginForm(props) {    
    const classes = useStyles()
    const {
        handleSubmit, handleChange,
        form, isLoggedIn
    } = props;
    const {loading} = useSelector(state => state.UI);
    const {user} = useSelector(state => state.auth);
    if (isLoggedIn) return <Redirect to={getPath(user)} />;
    return (
        <form onSubmit={handleSubmit}>
            <Grid container justify="center" className={classes.root}>
                <AlertDialog
                    title="Ingreso Fallido"
                />
                
                <ClinicalTextField sm={12} name={props.name ?? "username"} form={form}
                    label={props.label ?? "Nombre de Usuario"} handleChange={handleChange}
                    InputProps={{
                        startAdornment: <InputAdornment position="start"><AccountCircleIcon/></InputAdornment>,
                      }}
                />

                <Grid item lg={12} sm={12} xs={12} className={classes.item}>
                    <PasswordField 
                        name="password"
                        value={form.password}
                        handleChange={handleChange}
                        
                        />                    
                </Grid>
                <Grid item lg={12} sm={12} className={classes.item}>
                    <Button
                        variant="contained"
                        color="primary"
                        type="submit"
                        disabled={loading}
                    >
                        Ingresar
                    </Button>
                </Grid>
            </Grid>
        </form>
    );
}
