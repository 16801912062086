import OutlinedInput from "@material-ui/core/OutlinedInput";
import FilledInput from '@material-ui/core/FilledInput';
import Input from '@material-ui/core/Input';
import { TYPE_FIELD } from '../../../helpers/utils'
export default function TypeInput(props){
    if(TYPE_FIELD === "standard")
        return <Input {...props}/>
    else if (TYPE_FIELD === "filled")
        return <FilledInput {...props}/>
    else if(TYPE_FIELD === "outlined")
        return <OutlinedInput {...props}/>
}