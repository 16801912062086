import { Container, makeStyles, useTheme } from "@material-ui/core";
import React from "react";
import { useHistory } from "react-router";
import { getTheme } from "../../../helpers/utils";
import { ModuloCard } from "../../core/Card/ModuloCard";
import TitleView from "../../core/TitleView";
const useStyles = makeStyles((theme) => ({
    root: {
        maxWidth: 345,
    },
    media: {
        height: 150,
    },
    container: {
        padding: "3rem",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-evenly",
        flexWrap:"wrap"
    },
}));
export const MainMenuView = () => {
    const theme = useTheme();
    const history = useHistory()
    const handleClick = (route) =>{
        history.push(`/app/${route}`)
    }
    const classes = useStyles();
    return (
        <>
            <TitleView title="Menú principal" />
            <Container className={classes.container}>
                <ModuloCard
                    handleClick={() => handleClick("consulta_externa")}
                    title="Consulta Externa"
                    urlImage={getTheme(theme,"Group-15")}
                >
                    Es el módulo encargado de gestionar ingreso de paciente,
                    diagnostico y demás en consulta externa
                </ModuloCard>
                <ModuloCard
                    title="Enfermería"
                    handleClick={() => handleClick("enfermeria")}
                    urlImage={getTheme(theme,"Group-17")}
                >
                    Es el módulo encargado de gestionar ingreso de paciente,
                    diagnostico, registro y seguimiento a pacientes
                </ModuloCard>
            </Container>
        </>
    );
};
