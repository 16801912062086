import { Container, makeStyles } from '@material-ui/core'
import React from 'react'
import { DoctorRoomCard } from '../../core/Card/RoomCard/DoctorRoomCard'
import { NurseRoomCard } from '../../core/Card/RoomCard/NurseRoomCard';
import TitleView from '../../core/TitleView'
const useStyles = makeStyles((theme) => ({
    container:{
        display:"flex",
        flexWrap:"wrap",
        "& > *":{
            margin:theme.spacing(1),
            // flex:`1 0 calc(75% - ${theme.spacing(2)})`,
            flex:`1 0 33.3333%`
        }
    }
}));

export const EnfermeriaView = () => {
    const classes = useStyles();
    return (
        <>
            <TitleView title="Enfermería"/>
            <Container className={classes.container}>
                <DoctorRoomCard id={1}/>
                <NurseRoomCard id={2}/> 
                <DoctorRoomCard id={3}/>
                <DoctorRoomCard id={4}/>
                <DoctorRoomCard id={5}/>
                <DoctorRoomCard id={"UCI"}/>
            </Container>
        </>
    )
}
