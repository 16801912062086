import React, { useRef, useState } from "react";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import TextField from "../Field/TextField";

import MedicinaAutocomplete 
    from "../Field/autocomplete/MedicinaAutocomplete";

import {getMedicines} from "../../../redux/actions/paciente";
import { useDispatch } from "react-redux";

import Paper from '@material-ui/core/Paper';
import AlertDialog from "../AlertDialog";
import { TableHeader } from "./TableHeader";
import FloatingAddButton from "../Buttons/TableButtons";
import { Snackbar } from "@material-ui/core";
import DescriptionAlerts from "../Alert";
const columns = [
    { id: "medicamento", label: "Medicamentos", size: 600 },
    { id: "cantidad", label: "Cantidad", size: 105 },
    { id: "preescripcion", label: "Preescripcion"},
];

const useStyles = makeStyles((theme) => ({
    container: {
        maxHeight: 440,
    },
    table: {
        minWidth: 480,
    },
    item:{
        display:"flex"
    },
    button:{
        justifyContent:"flex-end"
    }
}));

export default function StickyHeadTable(props) {
    const classes = useStyles();
    const [open, setOpen] = useState(false)
    const { form, handleChange, handleAdd } = props;
    const [medicamentos, setMedicamentos] = React.useState([]);
    const [title, setTitle] = useState("")
    const dispatch = useDispatch();
    const ref = useRef(dispatch);
    React.useEffect(() => {
        ref.current(getMedicines())
            .then(response => {
                setMedicamentos(response);
            })
            .catch(()=>{
                setTitle("Advertencia :0");
            })
    }, []);
    const handleClick = () =>{
        setOpen(true);
        handleAdd();
    };
    const handleClose = (e,reason)=>{
        if (reason === 'clickaway')
            return;
        setOpen(false)
    }
    return (
        <Grid container className={props.styles.item}>
            <AlertDialog
                title={title}
            />
            <TableContainer className={classes.container} component={Paper}>
                <Table className={classes.table} stickyHeader aria-label="sticky table">
                    <TableHeader headers={columns}/>
                    <TableBody>
                        {form.treatment.map((e) => {
                            return (
                                <TableRow
                                    hover
                                    role="checkbox"
                                    tabIndex={-1}
                                    key={e.row}
                                >
                                    <TableCell>                                            
                                        <MedicinaAutocomplete
                                            handleChange={
                                                handleChange
                                                }                                                
                                            form={e}
                                            name="medicamento"
                                            medicamentos={medicamentos}
                                            
                                        />                                            
                                    </TableCell>
                                    <TableCell>
                                        <TextField
                                            required={e.required}
                                            sm={12}
                                            name="cantidad"
                                            form={e}
                                            label=""
                                            type="number"
                                            inputProps={{
                                                min:1, max:999
                                            }}
                                            handleChange={(event) =>
                                                handleChange(event, e.row)
                                            }
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <TextField
                                            required={e.required}
                                            sm={12}
                                            name="preescripcion"
                                            form={e}
                                            label=""
                                            handleChange={(event) =>{                                                        
                                                    handleChange(event, e.row)
                                                }                                                    
                                            }
                                        />
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>

            <FloatingAddButton
                onClick={handleClick}
            />
            <Snackbar
                anchorOrigin={{ vertical:"bottom", horizontal:"right" }}
                open={open}
                autoHideDuration={2000}
                onClose={handleClose}
            >
                <DescriptionAlerts description="Fila añadida en la tabla :)"/>
            </Snackbar>
        </Grid>
    );
}
