import {ui} from "./types"
export const uiOpen = (check) =>({
    type:ui.open,
    payload: check,
})

export const uiLoading = (isLoad) => ({
    type: ui.loading,
    payload: isLoad,
});

export const setUiMessage = (message) => ({
    type: ui.setMessage,
    payload: message,
});

export const clearUiMessage = () => ({
    type: ui.clearMessage,
});
export const changeUiNightMode = (checked) =>({
    type: ui.nightMode,
    payload: checked,
})
export const setDialog = (isOpen) =>({
    type: ui.dialog,
    payload: isOpen,
})

export const setDialogTurn = (isOpen) =>({
    type: ui.dialogMajor,
    payload: isOpen,
})

export const setDialogView = (isOpen) =>({
    type: ui.dialogView,
    payload: isOpen,
})

export const setCargarView = (isOpen) =>({
    type: ui.cargarView,
    payload: isOpen,
})


