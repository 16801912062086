import React from "react";
import DatosPersonalesView from "../components/views/PersonalData/DatosPersonalesView";
import DatosClinicoView from "../components/views/ClinicalData/DatosClinicoView";
import TurnView from "../components/views/Turns/FichaMedicaView";
import Default from "../components/views/Default";
import { Route } from "react-router";
import NavBar from "../components/core/navbar/NavBar";
import { MedicalHistoryView } from "../components/views/MedicalHistory/MedicalHistoryView"
import { cePath } from "../helpers/utils";
import GeneralDashboardRouter from "./GeneralDashboardRouter";
import ExamenesView from "../components/views/Examen/ExamenView";

export default function DashboardRouter() {
    return (
        <NavBar>
            <GeneralDashboardRouter>
                <Route exact path={`${cePath}`} component={Default} />
                <Route path={`${cePath}/ficha_medica`} component={TurnView} />
                <Route
                    path={`${cePath}/datos_personales`}
                    component={DatosPersonalesView}
                />
                <Route
                    path={`${cePath}/historial_clinico`}
                    component={MedicalHistoryView}
                />
                <Route
                    path={`${cePath}/datos_clinico`}
                    component={DatosClinicoView}
                />
                <Route
                    path={`${cePath}/examen`}
                    component={ExamenesView}
                />
            </GeneralDashboardRouter>            
        </NavBar>
    );
}
