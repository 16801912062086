import React from "react";
import ItemWithinLink from "./ItemWithinLink";
import { Link as RouterLink } from "react-router-dom";
import { logoutAuth } from "../../../redux/actions/auth";
import { useDispatch } from "react-redux";
export default function ItemNormal(props) {
    const { to,item } = props;
    const dispatch = useDispatch();
    const logOut = () => {
        dispatch(logoutAuth());
    };
    

    const renderLink = React.useMemo(
        () =>
            React.forwardRef((itemProps, ref) => (
                <RouterLink to={to} ref={ref} {...itemProps} />
            )),
        [to]
    );    
    return(
        <React.Fragment>
            {props.item.route === "/login" ?
                <ItemWithinLink item={item} onClick={logOut} />:
                <ItemWithinLink item={item} component={renderLink} /> }            
        </React.Fragment>
        
    ) 
}
