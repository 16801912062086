import { Container, Typography, useTheme } from "@material-ui/core";
import React from "react";
import TitleView from "../core/TitleView";
import { makeStyles } from '@material-ui/core/styles';
import { getTheme } from "../../helpers/utils";
const useStyles = makeStyles((theme) => ({
    root: {
        display:"flex",
        alignItems:"center",
        justifyContent:"space-between",
        [theme.breakpoints.down('md')]: {
            flexWrap:"wrap"
        },
    },
    image:{
        maxWidth:"50%",
        marginRight:theme.spacing(2),
        [theme.breakpoints.down('md')]: {
            maxWidth:"100%",
            marginRight:0,
            marginBottom: theme.spacing(2)
        },
        objectFit:"cover",
    }
}));

export default function Default() {
    const classes = useStyles();
    const theme = useTheme();
    const typeImage = getTheme(theme,"Group-15")
    return (
        <React.Fragment>
            <TitleView title="Bienvenido al sistema"/>
            <Container className={classes.root} >
                <img src={typeImage} alt="clinica home" className={classes.image} loading="lazy"/>
                <Typography paragraph>
                    En este sistema podrás ingresar los datos del paciente si eres
                    enfermero/a o revisar las fichas medicas pendientes si eres
                    doctor/a :D
                </Typography>
            </Container>
        </React.Fragment>
    );
}
