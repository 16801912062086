import { configureStore } from '@reduxjs/toolkit';
import { combineReducers, applyMiddleware } from 'redux'
import thunk from 'redux-thunk'
import auth from './reducers/auth'
import paciente from './reducers/paciente'
import notifies from './reducers/notifies'
import archivos from './reducers/examenes'
import { UI } from './reducers/ui'
import { fichaMedica } from './reducers/fichaMedica'
const reducers = combineReducers({
    auth,
    UI,
    paciente,
    notifies,
    fichaMedica,
    archivos,
})
const store = configureStore({
    reducer: reducers,    
}, applyMiddleware(thunk))
export default store